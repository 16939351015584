import { useEffect, useState } from 'react';
import { ZodError, z } from 'zod';
import { RequestError, RequestResponse } from '../../services/api';
import Alert from '../../helpers/Alert';
import { useParams } from 'react-router';

export interface UseFormProps<T> {
  validator?: (formData: Partial<T>, other: { fromZodError: (e: ZodError) => void }) => boolean | any;
  initialData?: Partial<T>;
  loader?: (id: string) => Promise<RequestError<any> | RequestResponse<T>>;
  loaded?: (data: T) => T;
  id?: number | string;
  beforeFormChange?: (newForm: Partial<T>, oldForm: Partial<T>) => Partial<T>;
}

export type UseFormData<T> = {
  form: Partial<T>,
  setForm: (form: Partial<T>) => void,
  handleChange: (value: string | number | boolean, name: keyof T) => void,
  isFormInvalid: boolean;
  register: (name: keyof T, options?: { 
    except?: ('value' | 'setValue' | 'error')[];
    getValue?: () => any;
    setValue?: (value: any) => void;

  }) => {
    error?: string;
    value?: any;
    setValue?: (value: any) => void
  };
  registerList: (name: string | keyof T, index: number) => {
    value: any;
    setValue: (value: any) => void;
  };
  errors: { [key: string]: string }, 
  setErrors: (errors: { [key: string]: string }) => void,
  fromZodError: (err: ZodError, field?: string) => void,
  loading: boolean;
  setLoading: (loading: boolean) => void;
  validateWithZod: (rules: any, data?: any, field?: string) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useForm<T = any>(params?: UseFormProps<T>) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [form, setForm] = useState<Partial<T>>((params?.initialData ?? {}));
  const isFormInvalid = params?.validator ? !handleValidator() : false;
  const routeParams = useParams();
  const id = params?.id ?? routeParams.id;

  function handleValidator() {
    try {
      if (params?.validator) {
        return params?.validator(form, {fromZodError});
      }
    } catch(e) {
      return false;
    }
  }

  function handleChange(value: string | number | boolean, name: keyof typeof form) {
    let d: any = { ...form, [name]: value };

    if (params?.beforeFormChange) {
      d = params.beforeFormChange(d, form);
    }
    setForm(d);
  }

  function register(
    name: keyof typeof form, 
    options?: { 
      except?: ('value' | 'setValue' | 'error')[],
      getValue?: () => any,
      setValue?: (value: any) => void
    }
  ) {
    let item = {};

    if (! options?.except?.includes('value')) {
      item = {
        value: options?.getValue ? options.getValue() : form[name] as any,
      }
    }

    if (! options?.except?.includes('setValue')) {
      item = {
        ...item,
        setValue: options?.setValue ? options.setValue : (value: string) => handleChange(value, name)
      }
    }

    if (! options?.except?.includes('error')) {
      item = {
        ...item,
        error: errors[name as string] ?? ''
      }
    }

    return item;
  }

  function registerList(name: keyof typeof form | string, index: number) {
    const keys = name.toString().split('.');

    const items = (form as any)[keys[0]];
    const row = items[index];
    let error = '';

    if (errors && errors[keys[0]] && errors[keys[0]][index] && errors[keys[0]][index][keys[1] as any]) {
      error = errors[keys[0]][index][keys[1] as any] as any;
    }

    const item = {
      value: row[keys[1]],
      setValue: (value: any) => {
        const d = [...(form as any)[keys[0]]];
        d[index][keys[1]] = value;
        setForm({ ...form, [keys[0]]: d });
      },
      error,
    };

    return item;
  }

  function fromZodError(e: ZodError, field?: string) {
    setLoading(false);
    setErrors({});
    let err = {};

    (e as any).issues.forEach((issue: any) => {
      if (field) {
        err = { 
          ...err, 
          [field]: {
            ...(err as any)[field],
            [issue.path[0]]: {
              ...( issue.path[0] ? (err as any)[field][issue.path[0]] : {}),
              [issue.path[1]]: issue.message
            }
          }
        };

      } else if (issue.path?.length > 1) {
        err = { 
          ...err, 
          [issue.path[0]]: {
            ...((err as any)[issue.path[0]] ?? {}),
            [issue.path[1]]: {
              ...((err as any)[issue.path[0]] ? (err as any)[issue.path[0]][issue.path[1]] ?? {} : {}),
              [issue.path[2]]: issue.message
            }
          }
        };
      }else {
        err = { ...err, [issue.path[0]]: issue.message };
      }
    });

    setErrors(err);
  }

  async function loadData() {
    if (! id || ! params?.loader) return;

    const response = await params.loader(id as any);
    if (response.isError) {
      return Alert.error((response.data as any)?.message || 'Falha ao carregar formulário');
    };

    let d = response.data as Partial<T>;

    if (params?.beforeFormChange) {
      d = params.beforeFormChange(d, form);
    }

    if (params.loaded) {
      d = params.loaded(d as T);
    }
    setForm(d);
  }

  function validateWithZod(rules: z.ZodObject<any>, data = form, field?: string) {
    setErrors({});
    try {
      rules.parse(data);
      return true;
    } catch (e) {
        fromZodError(e as ZodError, field);
        return false;
    }
  }

  useEffect(() => {
    loadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    form,
    setForm,
    registerList,
    handleChange,
    isFormInvalid,
    register,
    errors, 
    setErrors,
    fromZodError,
    loading,
    setLoading,
    validateWithZod,
  } as UseFormData<T>;
}
