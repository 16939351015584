import { useNavigate } from "react-router-dom";
import Button from "../../button/Button";
import { tw } from "@matheuscaetano/helprs";
import { Txt } from "../../text/Txt";

export type PageHeaderProps = {
  children?: React.ReactNode;
  className?: string;
}

export function PageHeader({
  children,
  className,
}: PageHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className={tw(className, 'col-span-full flex w-full items-center justify-between')}>
        <Txt variant="page-title">{children}</Txt>
        <Button onClick={() => navigate(-1)}>
            Voltar
        </Button>
    </div>
  );
}
