import PageContainer from "../../components/container/PageContainer";
import React, {useState} from "react";
import Button from "../../components/button/Button";
import useTable from "../../hooks/useTable";
import lembretesApi from "../../services/lembretesApi";
import Table from "../../components/table/Table";
import { useNavigate } from 'react-router';
import Alert from "../../helpers/Alert";
import Input from "../../components/input/Input";
export default function Lembretes() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { tableProps, onSearch } = useTable({
        dataLoader: async (params) => {
            const { data, isError } = await lembretesApi.all(params);
            setLoading(false);
            return isError ? false : data;
        }
    });
    const [search, setSearch] = useState('');

    const columns = [
        { title: 'Id', field: 'id' },
        { title: 'Descrição', field: 'descricao' },
        { title: 'Categoria', field: 'categoria' },
        { title: 'Data', field: 'data', type: 'date' },
    ];

    const onEdit = (lembreteData: any) =>{
        navigate(`/lembretes/cadastro/${lembreteData.id}`);
    }

    const onDelete = async (lembreteData: any) => {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir Lembrete?`
        );
        if (!confirm) return;
        Alert.await(`Aguarde...`);
        const {data, isError} = await lembretesApi.destroy(lembreteData.id);
        Alert.close();
        if (isError) return Alert.error(data.message);
        Alert.success('Deletado com sucesso');
        await onSearch('');

    }

    /*const handleChange = (value: any, name: string) => {
        setSearch(value);
        onSearch({search: value})
    }*/

    return(
        <PageContainer footer={false}>
            <div className={'flex'}>
                <div className={'flex flex-row justify-between p-2 w-full'}>
                    <h1 className={'text-3xl flex items-center'}>Lista lembretes</h1>
                    <Button className={'bg-constancia hover:bg-constancia-100 text-white'} onClick={() => navigate('/lembretes/cadastro')}>Novo</Button>
                </div>
            </div>
            {/*<div className={'flex'}>
                <Input
                    className={'md:w-1/2'}
                    label="Descrição"
                    value={search}
                    setValue={v => handleChange(v, 'descricao')}
                />
            </div>*/}
            <div className={'mt-5 '}>
                <Table
                    loading={loading}
                    columns={columns}
                    data={tableProps.data}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            </div>
        </PageContainer>
    )
}