import { useEffect, useState } from "react";
import { Button, Checkbox, DashCard, Input, ListPageLayout, Select } from "../../components";
import Form from "../../components/form/Form";
import { alunoApi, vendaClienteApi } from "../../services";
import { useVendaClienteForm } from "../../hooks";
import InputCurrency from "../../components/inputCurrency";
import AsyncSelect from 'react-select/async';

export default function VendasClientes() {
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [info, setInfo] = useState({ quantidade: 0, total: 0 });
  const [id, setId] = useState<number>();

  async function loadInfo() {
    const { data, isError } = await vendaClienteApi.listAll({ 'where': `data,${date},${date}` });
    if (isError ) return;
    let v = 0;
    let t = 0;
    data.forEach((item: any) => {
      v += Number(item.valor);
      t++;
    });
    setInfo({ quantidade: t, total: v});
  }

  useEffect(() => {
    loadInfo();
  }, [date])

  return (
    <ListPageLayout 
      title="Vendas Clientes"
      deletion={{ handler: vendaClienteApi.destroy }}
      queries={{
        'where': `data,${date},${date}`
      }}
      header={
        <div className="grid grid-cols-12 gap-4 items-end">
          <Input
              type="date"
              className="col-span-full lg:col-span-2"
              value={date}
              setValue={(e) => setDate(e)}
          />

          <div className="col-span-full lg:col-span-1"></div>

          <div className="grid grid-cols-3 gap-4 col-span-full lg:col-span-9 align-bottom items-center">
            <div></div>
            <DashCard label="Vendas" value={info.quantidade} />
            <DashCard label="Total" value={info.total} type="money" />
          </div>
        </div>
      }
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (item) => setId(item?.id),
      }}
      table={{
        loader: vendaClienteApi.all,
        columns: [
            { title: 'Nome', field: 'aluno.nome' as any },
            { title: 'Produto', field: 'descricao' },
            { title: 'Categoria', field: 'categoria_id' },
            { title: 'Valor', field: 'valor', columnType: 'money' },
            { title: 'Vencimento', field: 'pagamento_data', columnType: 'date' },
            { title: 'Pagamento', field: 'pagamento_forma' },
            { title: 'Pago', field: 'pago', columnType: 'YesOrNo' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    form,
    setForm,
    errors,
    register,
    save,
    categorias,
    formaPagamento
  } = useVendaClienteForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <AsyncSelect 
        value={form.aluno ? { value: form.aluno.id, label: form.aluno.nome } : undefined}
        onChange={(value: any) => {
          setForm({ ...form, aluno_id: value.value, aluno: { id: value.value, nome: value.label } as any });
        }}
        className="col-span-full"
        loadOptions={async (q: any) => {
          const { data, isError } = await alunoApi.all({ like: ['nome', q] });
          if (isError) return [];
          return data.data.map((opt: any) => ({ value: opt.id, label: opt.nome }));
        }} 
      />


      <Input 
        required
        label="Produto"
        className="col-span-full lg:col-span-7"
        {...register('descricao')}
      />

      <Select 
        required
        label="Categoria"
        className="col-span-full lg:col-span-5"
        {...register('categoria_id')}
        options={categorias.asSelectOptions}
      />

      <InputCurrency
          label="Valor"
          value={form.valor}
          className="col-span-full lg:col-span-6"
          onChange={(value: any) => setForm({ ...form, valor: value })}
          error={errors?.valor}
      />

      <Input 
        required
        label="Data"
        type="date"
        className="col-span-full lg:col-span-6"
        {...register('data')}
      />

      <Select 
        required
        label="Pagamento"
        className="col-span-full lg:col-span-4"
        {...register('pagamento_forma')}
        options={formaPagamento.asSelectOptions}
      />

      <Input 
        required
        label="Data Vencimento"
        type="date"
        className="col-span-full lg:col-span-4"
        {...register('pagamento_data')}
      />

      <Select 
          required
          label="Notificar"
          className="col-span-full lg:col-span-4"
          {...register('pagamento_tipo_notificacao')}
          options={[
              {value: 'WhatsApp', label: 'WhatsApp'},
              {value: 'E-mail',   label: 'E-mail'},
              {value: 'SMS',      label: 'SMS'},
          ]}
      />

      <Checkbox
        label="Está pago?"
        className="col-span-full"
        {...register('pago')}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

