import { Material } from "../models"
import { ISelect } from "../types";

export function mapMateriaisToSelectOptions(materiais?: Material[]): ISelect[] {
  return materiais?.map(material => {
    return {
      value: material.id,
      label: material.nome
    }
  }) ?? [];
}