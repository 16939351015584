import Select from "react-select";
import Str from "../../helpers/Str";
import {IInputSelect} from "../../types";

export type SelectOption<V = string, L = string> = {
    value: V;
    label: L;
}

export default function InputSelect({
    selected,
    className,
    margintopClassName,
    label,
    classNameContainer,
    loading,
    options,
    onChangeSelect,
    placeholder,
    isMulti,
    isOptionDisabled,
    isDisabled,
    key,
    value,
    setValue,
    disabled,
    error,
    required
}: IInputSelect){
    const inputSelectStyle = () => ({
        paddingBottom: '5px',
        paddingTop: '6px',
    });

    const meuPla =  'auto';
    /*if (menuPlacement !== undefined){
        console.log(menuPlacement);
        meuPla = menuPlacement;
    }*/
    return(
        <div className={className ?? classNameContainer}>
            {
                label ?
                    <label
                        className="font-medium"
                    >
                        {label}
                        {required ? <span className='text-red-600'> *</span> : ''}
                    </label>
                    :
                    ''
            }

            <Select
                menuPlacement={meuPla}
                isClearable={!required}
                key={key}
                isLoading={loading}
                placeholder={placeholder ?? ''}
                value={value ? (isMulti ? options.filter((opt) => value.includes(opt.value)) : options.filter((opt) => opt.value === value)) : selected}
                className={Str.tw(
                    'uppercase',
                    margintopClassName ? (margintopClassName) : (label ? 'mt-[0px]' : 'lg:mt-[1.5rem] sm:mt-[1rem] min-[320px]:mt-[5px]'),
                )
                }
                styles={
                    {
                        input: (styles) => ({ ...styles, ...inputSelectStyle() }),
                        control: (styles) => ({ ...styles, borderRadius: '0.375rem' }),
                        
                        
                    }
                }
                options={options}
                onChange={setValue ? (e: any) => setValue(isMulti ? e.map((e: any) => e?.value ?? null) : e?.value ?? null) : onChangeSelect}
                isMulti={isMulti}
                isOptionDisabled={isOptionDisabled}
                isDisabled={isDisabled ?? disabled}
            />
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </div>
    )
}