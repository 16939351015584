import { AlunoCurso } from "../models";
import { alunoCursoAmountOfMonths } from "./aluno-curso-amount-of-months";

export function alunoCursoMonthlyValue(alunoCurso: Partial<AlunoCurso>, withDiscount = false, options?: { formatar?: boolean }) {
  if (!alunoCurso?.curso?.valores || !alunoCurso?.planos?.length) return '';

  const months = alunoCursoAmountOfMonths(alunoCurso);
  if (! months) return '0.0';

  let price = 0;

  const planosToKey = {
      'Outro': 'valor_avulso', 
      'Mensal': 'valor_mensal', 
      'Trimestral': 'valor_trimestral', 
      'Semestral': 'valor_semestral',
  };

  alunoCurso.planos.forEach(plano => {
      const value = (alunoCurso.curso?.valores as any)[planosToKey[plano]];
      if (value) price += Number(value);
  });

  if (withDiscount && alunoCurso.porcentagem_desconto) {
      price = price - (price * (Number(alunoCurso.porcentagem_desconto) / 100));
  }

  // price = price / months; 

  if (options?.formatar) {
    return price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }
  return price.toFixed(2);
}
