import { useQuery } from "react-query";
import { Curso } from "../../models";
import cursosApi, { Params } from "../../services/cursosApi";
import { ISelect } from "../../types";

export function useCursos() {
  const query = useQuery<Curso[]>('cursos.all', async () => {
    const { data, isError } =  await cursosApi.listAll({
      query: {
        relations: ['categoria', 'valores', 'salas', 'professores.disponibilidadeCurso', 'professores.disponibilidade']
      }
    });
    if (isError) throw new Error('Erro ao buscar cursos');
    return data;
  }, {
    initialData: [],
  });

  return {
    cursos: query.data as Curso[],
    cursosAsSelectOptions: query.data?.map((c) => ({ value: c.id, label: c.descricao })) as ISelect[],
    loading: query.isLoading
  }
}
