import React from 'react';
import Str from "../../../helpers/Str";
export interface CardBasicExampleProps {
    titulo: string;
    className: string;
    classNameTitle?: string;
    data?: any;
    onClick?: any;
}
export default function CardBasicExample({
 titulo,
 className,
 classNameTitle,
 data,
 onClick
}: CardBasicExampleProps): JSX.Element {
    return (
        <div onClick={onClick} className={Str.tw(className,"flex border-2 border-current flex-col  dark:bg-neutral-700  rounded-md cursor-pointer mt-3 ")}>
            <h5 className={Str.tw(classNameTitle,"text-[30px] font-medium leading-tight  text-center")}>
                {titulo}
            </h5>
            {
                <div className={'grid grid-cols-3 text-[14px]'}>
                    {
                        data && data.map((dt: any) => {
                            return(
                                <div className={'mt-3 ml-2 mr-2 mb-2 border-2 border-slate-200 rounded-md p-2 flex flex-col justify-between'}>
                                    <div>{dt.descricao.length > 95 ? dt.descricao.slice(0, 95)+"..." : dt.descricao}</div>
                                    <div>Data: {Str.convertDate(dt.data)} - Hora: {dt.hora}</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}