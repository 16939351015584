import { tw } from "@matheuscaetano/helprs";
import { PageHeader } from "../../headers/PageHeader";

export type FormContainerProps = {
  className?: string;
  children: React.ReactNode;
  contentClassName?: string;
  title?: string;
  onSubmit?: () => void;
}

export function FormContainer({
  className,
  contentClassName,
  children,
  title,
  onSubmit,
}: FormContainerProps)  {

  function handleSubmit(e: any) {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  }

  return (
    <div className={tw(className, 'flex justify-center mt-5 lg:mb-[30px]')}>
        <div className={tw(contentClassName, 'lg:w-[80rem] min-[320px]:w-auto  border p-5')} >
          <PageHeader className="mb-4">{title}</PageHeader>

          <form onSubmit={handleSubmit}>
            {children}
          </form>
        </div>
    </div>
  );
}
