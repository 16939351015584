import { Material } from '../models';
import { QueryParams, IMateriais } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'materiais';

export default {
    all: async (params?: QueryParams) => getAll<IMateriais>({ endpoint, params }),
    listAll: async () => listAll<Material>({ endpoint }),
    one: async (id: string) => getOne<IMateriais>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<IMateriais>) => create<IMateriais>({ endpoint, formData }),
    update: async (id: string, formData: Partial<IMateriais>) => update<IMateriais>({ endpoint, formData, id }),
};
