import { useEffect, useState } from "react";
import { Button, Calendar, Checkbox, Input, Modal, PageContainer, Select, Txt } from "../../components";
import { useEventoForm, useModal } from "../../hooks";
import Alert from "../../helpers/Alert";
import { eventoApi } from "../../services";
import { Evento } from "../../models";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { DateSelectArg, EventClickArg } from "@fullcalendar/core";
import { Dt } from "../../helpers";
import Form from "../../components/form/Form";
import dayjs from "dayjs";

export default function Calendario() {
  const eventRegistrationModal = useModal();
  const [id, setId] = useState<number>();
  const { form, setForm, register, registerList, cursos, save, destroy } = useEventoForm({ id });
  const [eventos, setEventos] = useState<Evento[]>([]);

  async function load() {
    Alert.await('Carregando eventos...');
    const { data, isError } = await eventoApi.listAll();
    Alert.close();
    if (isError) return Alert.error(data.message || 'Falha ao carregar eventos.');
    setEventos([ ...eventos, ...data ]);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { load(); }, []);

  function onSelect(data: DateSelectArg) {
    setForm({ 
      inicio: data.startStr,
      fim: Dt.minusOneDay(data.endStr),
    });
    eventRegistrationModal.open();
  }

  function onEventClick(data: EventClickArg) {
    setId(Number(data.event.id));
    eventRegistrationModal.open();
  }

  return (
    <PageContainer>
      <Calendar 
        onSelect={onSelect}
        onEventClick={onEventClick}
        events={eventos.map((e: Evento) => ({
          id: e.id.toString(),
          allDay: true,
          title: e.nome,
          start: e.inicio,
          end: dayjs(e.fim).add(1, 'day').format('YYYY-MM-DD'), // Por alguma razão o fullcalendar não conta o fim do evento, por isso adicionamos um dia
          color: e.cor,
        }))}
      />

      <Modal 
        {...eventRegistrationModal.modalProps} 
        title="Cadastrar evento"
        size="xl"
      >
        <Form className="grid gap-4 grid-cols-12" onSubmit={save}>
          <Input 
            label="Evento"
            className="col-span-full lg:col-span-8"
            {...register('nome')}
          />

          <Select 
            label="Cor"
            className="col-span-full lg:col-span-4"
            {...register('cor')}
            options={[
              {
                value: '#316487',
                label: <div className="w-full h-4 bg-[#316487]"></div>
              },
              {
                value: '#8678A3',
                label: <div className="w-full h-4 bg-[#8678A3]"></div>
              },
              {
                value: '#E6DB31',
                label: <div className="w-full h-4 bg-[#E6DB31]"></div>
              },
              {
                value: '#159953',
                label: <div className="w-full h-4 bg-[#159953]"></div>
              },
              {
                value: '#B32117',
                label: <div className="w-full h-4 bg-[#B32117]"></div>
              },
            ]}
          />

          <Input 
            label="Data"
            type="date"
            className="col-span-full lg:col-span-6"
            {...register('inicio')}
          />

          <Input 
            label="Fim"
            type="date"
            className="col-span-full lg:col-span-6"
            {...register('fim')}
          />

          <Checkbox
            label="Considerar na Contagem de Aulas"
            className="col-span-full"
            {...register('considerar_contagem_aulas')}
          />

        <header className="flex items-center gap-4 col-span-full">
          <Txt variant="title">Referenciar Cursos</Txt>

          <button 
            type='button' 
            title="Adicionar Curso" 
            onClick={cursos.add}
          >
            <FiPlusCircle color={'#30694A'} size={30}/>
          </button>
        </header>

        {form.evento_cursos?.map((eventoCurso, i) => <div className="col-span-full grid grid-cols-12 gap-4">
          <Select
            required
            label='Curso'
            className={'col-span-full lg:col-span-10'}
            {...registerList('evento_cursos.curso_id', i)}
            options={cursos.asSelectOptions}
          />

          <button 
              type='button' 
              title="Remover Curso"
              className="mt-5"
              onClick={() => cursos.remove(i)}
            >
              <FiMinusCircle color={'darkred'} size={30}/>
            </button>
          </div>
        )}

          <footer className="flex items-center justify-end col-span-full gap-4">
            <Button onClick={() => {
              eventRegistrationModal.close();
              destroy();
            }}>Excluir</Button>
            <Button variant="constancia" type="submit">Cadastrar</Button>
          </footer>
        </Form>
      </Modal>
    </PageContainer>
  )
}