import { useState } from "react";
import { DashCard, Input, ListPageLayout } from "../../components";
import Str from "../../helpers/Str";
import { alunoApi } from "../../services";
import Alert from "../../helpers/Alert";

export default function AlunosList() {
    const [key, setKey] = useState(1);
    const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));

    async function handlePago(item: any) {
        const confirm = await Alert.confirm(
            "Deseja confirmar o pagamento do Aluno?",
            "Confirmar Pagamento?",
        );

        if (!confirm) return;
        alunoApi.definirPagamento({...item, pago: !item.pago});
        setKey(key + 1);
    }

    return (
        <ListPageLayout
            unpaginated
            key={key}
            title="Clientes Ativos"
            queries={{ date }}
            registerModal={{
                hideCreationButton: true,
                avoidRender: true,
                form: <></>,
                onOpen: handlePago,
            }}
            header={(data) => (
                <div className="grid grid-cols-12 gap-4 items-end">
                    <Input
                        type="date"
                        className="col-span-full lg:col-span-2"
                        value={date}
                        setValue={(e) => setDate(e)}
                    />

                    <div className="col-span-full lg:col-span-1"></div>

                    <div className="grid grid-cols-3 gap-4 col-span-full lg:col-span-9 align-bottom items-center">
                        <DashCard label="Modalidades" value={new Set(data?.filter(item => item.categoria_nome).map(item => item.categoria_nome)).size} />
                        <DashCard label="Responsáveis" value={new Set(data?.map(item => item.responsavel_nome)).size} />
                        <DashCard label="Cursos" value={new Set(data?.filter(item =>  (item as any).curso_id).map(item => (item as any).curso_id)).size} />
                        <DashCard label="Itens Pagos" value={`${data?.filter(item => item.pago).length}/${data?.length}`} />
                        <DashCard label="Previsto" value={Str.currencyMask(data?.reduce((acc, item) => acc + Number(item?.valor), 0))} />
                        <DashCard label="Total" value={Str.currencyMask(data?.filter(item => item.pago).reduce((acc, item) => acc + Number(item?.valor), 0))} />
                    </div>
                </div>
            )}
            table={{
                loader: alunoApi.alunoList as any,
                columns: [
                    { title: 'Responsável ', field: 'responsavel_nome' },
                    { title: 'CPF', field: 'responsavel_cpf', columnType: 'CPF' },
                    { title: 'Curso', field: 'curso_nome' },
                    { title: 'Categoria', field: 'categoria_nome' },
                    { title: 'Extra', field: 'extra_nome' },
                    { title: 'Notificação', field: 'tipo_notificacao' },
                    { title: 'Ref. Meses', field: 'ref_dates' },
                    { title: 'Forma Pag.', field: 'pagamento_forma' },
                    { title: 'Valor', field: 'valor', columnType: 'money' },
                    { title: 'Pago', field: 'pago', columnType: 'YesOrNo' },
                ],
            }}
        />
    )
}
