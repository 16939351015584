import PageContainer from "../../../components/container/PageContainer";
import {useEffect, useState} from "react";
import InputSelect from "../../../components/inputSelect";
import Button from "../../../components/button/Button";
import diasApi from "../../../services/diasApi";
import salasApi from "../../../services/salasApi";
import turnosApi from "../../../services/turnosApi";
import { MdEdit } from "react-icons/md";
import Input from "../../../components/input/Input";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import Form from "../../../components/form/Form";
import professoresApi from "../../../services/professoresApi";
import horariosSalasTurnoApi from "../../../services/horariosSalasTurnoApi";
import Alert from "../../../helpers/Alert";
import { PageHeader } from "../../../components";
import { tw } from "@matheuscaetano/helprs";
export interface IDataSearch{
    sala_id?: number | null,
    dia_id?: number | null,
    turno_id?: number | null,
}

export default function TabelaDisponibilidades() {
    const [turnosOptions, setTurnosOptions]: any[] = useState([]);
    const [diasOptions, setDiasOptions]: any[] = useState([]);
    const [salasOptions, setSalasOptions]: any[] = useState([]);
    const [dataSearchOptionsSelected, setDataSearchOptionsSelected]: any = useState({
        sala_id: [],
        dia_id: [],
        turno_id: [],
    });
    const [listData, setListData]: any = useState([])
    const [cursos, setCursos]: any = useState([])
    const [sala, setSala]: any = useState([])
    const [loadingData, setLoadingData] = useState(false);
    const [loadingFormModal, setLoadingFormModal] = useState(false);
    const diasDataApi = [...diasApi];
    const [modalData, setModalData]: any = useState();
    const [professorOptions, setProfessorOptions]: any = useState();
    const [professorSelected, setProfessorSelected]: any = useState([]);
    const [cursosId, setCursosId]: any = useState([]);

    const { modalProps, open, close } = useModal();


    useEffect(() => {
        getData()
    },[]);

    async function getData() {
        Alert.await();
        const [turnosData, salasData] = await Promise.all([
            turnosApi.listAll(),
            salasApi.listAll()
        ]);
        if (!turnosData.isError){
            const optTur = turnosData.data.map((dt) => ({ value: dt.id, label: dt.nome}));
            optTur.unshift({ value: '', label: 'Todos' })
            setTurnosOptions(optTur);
        }
        if (!salasData.isError){
            const optSala = salasData.data.map((sl) => ({ value: sl.id, label: sl.nome+' - '+sl.codigo}));
            setSalasOptions(optSala);
        }
        const diasOpt = [...diasApi].map((dia) => ({ value: dia.id, label: dia.name }));
        // @ts-ignore
        diasOpt.unshift({ value: '', label: 'Todos' })
        setDiasOptions(diasOpt);
        Alert.close();
    }


    const onSelect = (e: any, nome: string) => {
        setDataSearchOptionsSelected({...dataSearchOptionsSelected, [nome]: e});
    }

    async function onSearch(){
        if (dataSearchOptionsSelected.sala_id.value === undefined) {
            Alert.error('Selecione uma sala');
            return
        }
        setLoadingData(true);
        const newData = {
            sala_id: dataSearchOptionsSelected.sala_id?.value,
            dia_id: dataSearchOptionsSelected.dia_id?.value,
            turno_id: dataSearchOptionsSelected.turno_id.value,
        }
        const {data, isError} = await salasApi.getSalaHorarios(newData);
        if (!isError){
            setCursosId(data.sala.cursos ? data.sala.cursos.map((curso: any) => curso.id) : [])
            setSala(data.sala);
            setCursos(data.sala.cursos);
            setListData(data.disponibilidades);
        }
        setLoadingData(false);
    }

    function checkObject(obj: any) {
        return typeof obj === 'object' && obj !== null;
    }
    const editOpen = (horario: any, diaId?: number) => {
        setProfessorSelected([]);
        onSearchProfessor(horario, diaId);
        setModalData(horario);
        if (horario.professor_id){
            setProfessorSelected({value: horario.professor_id, label: horario.professor.nome})
        }
        open()
    }

    async function onSearchProfessor(horarios?: any, diaId?: number){
        const url = `?inicio_aula=${horarios.inicio_aula}&final_aula=${horarios.final_aula}&cursos=${cursosId}&diaId=${diaId}`
        const {data, isError} = await professoresApi.getProfessorHorarios(url);
        if (!isError){
            const professorOpt = data.map((opt: any) => ({ value: opt.id, label: opt.nome }));
            setProfessorOptions(professorOpt);
        }
    }

    const onSubmitModal = async () => {
        setLoadingFormModal(true);
        const dataForm = { professor_id: professorSelected.value };
        const {data, isError} = await horariosSalasTurnoApi.update(modalData.id, dataForm);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        onSearch();
        close()
        setLoadingFormModal(false);
    }

    return (
        <PageContainer>
            <div className={'flex flex-col  p-2 w-full mb-2'}>
                <PageHeader>Resumo</PageHeader>

                <div className={'grid sm:grid-cols-1 md:grid-cols-10 lg:grid-cols-12 gap-2 w-full'}>
                    <div className={'col-span-2'}>
                        <InputSelect 
                            selected={dataSearchOptionsSelected.sala_id} 
                            options={salasOptions} 
                            onChangeSelect={(e: any) => onSelect(e, 'sala_id')} 
                            placeholder={'Salas'}
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <InputSelect 
                            selected={dataSearchOptionsSelected.turno_id} 
                            options={turnosOptions} 
                            onChangeSelect={(e: any) => onSelect(e, 'turno_id')} 
                            placeholder={'Turnos'}
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <InputSelect 
                            selected={dataSearchOptionsSelected.dia_id} 
                            options={diasOptions} 
                            onChangeSelect={(e: any) => onSelect(e, 'dia_id')} 
                            placeholder={'Dia da semana'}
                        />
                    </div>
                    <div className={'col-span-1 mt-6'}>
                        <Button variant="conhecimento" onClick={onSearch}>Buscar</Button>
                    </div>
                </div>
            </div>

            <div>
            {
                loadingData ?
                    <span className="flex items-center justify-center w-full">
                        <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <>
                        {
                            cursos.length ?
                                <>
                                    <div>
                                        <span>{cursos.length ? 'Atividades indicadas' : ''}</span>
                                    </div>
                                    <div className={'grid gap-2 grid-cols-12'}>
                                        {
                                            cursos.length ? cursos.map((curso: any, index: number) => {
                                                return(
                                                    <Input
                                                        className={'col-span-2'}
                                                        disabled={true}
                                                        value={curso.descricao}
                                                    />
                                                )
                                            }) : ''
                                        }

                                    </div>
                                    <div className={'grid gap-2 grid-cols-12 mt-2'}>
                                        {
                                            checkObject(sala) ?
                                                <Input
                                                    label={'Pavimento'}
                                                    className={'col-span-2'}
                                                    disabled={true}
                                                    value={sala.pavimento ? sala.pavimento.nome : ''}
                                                />
                                                :
                                                ''
                                        }
                                    </div>
                                </> : ''
                        }
                        {
                            (listData.length ? listData.map((list: any) => {
                                const dia = diasDataApi.find((dia) => dia.id === list.dia);

                                return(
                                    <div className={'flex flex-row mb-5 mt-5'}>
                                        <div className={'flex border rounded-md justify-center items-center w-[5rem] border-2 px-3 mr-1 '}>
                                            {
                                                dia?.abreviacao
                                            }
                                        </div>
                                        <div className={'flex flex-col w-full'}>
                                            {
                                                list.get_disp_turnos.map((turno: any) => {
                                                    let turnoTxt = '';
                                                    if (turno['turno']){
                                                        turnoTxt = turno['turno']['nome'].substring(3, -5)
                                                    }
                                                    return(
                                                        <div className={`flex flex-row w-full mb-1`}>
                                                            <div className={'flex border rounded-md justify-center items-center w-[5rem] border-2 px-3 mr-1 '}>
                                                                {turnoTxt}
                                                            </div>
                                                            <div className={'flex flex-col w-full'}>
                                                                {
                                                                    turno.horarios.map((horario: any) => {
                                                                        return(
                                                                            <div className={'flex flex-row w-full mb-1'}>
                                                                                <div className={'flex border rounded-md justify-center items-center w-[20rem] border-2 px-3 mr-1 '}>
                                                                                    {horario.inicio_aula.slice(0, -3)+' - '+horario.final_aula.slice(0, -3)}
                                                                                </div>
                                                                                <div className={`flex border rounded-md justify-center items-center h-[2rem] w-full border-2 px-3 mr-1 ${horario.desabilitado && !horario.professor_id ? 'bg-gray-100 text-gray-400' : (horario.professor ? '' : 'bg-red-100')}`}>
                                                                                    {horario.desabilitado && !horario.professor_id ? 'Almoço' : horario.professor ? horario.professor?.nome : ''}
                                                                                </div>
                                                                                <div className={'flex border rounded-md justify-center items-center h-[2rem] w-[30rem] border-2 px-3 mr-1 '}>
                                                                                    {sala.pavimento ? sala.pavimento.nome : ''}
                                                                                </div>
                                                                                <div
                                                                                    className={tw(
                                                                                        'flex border rounded-md justify-center items-center  border-2 px-3 mr-1 cursor-pointer',
                                                                                        !!horario.horario_sala_turno_inicial_id ? 'cursor-default  opacity-0' : ''
                                                                                    )}
                                                                                    title={'Editar Horário'}
                                                                                    onClick={(e: any) => !!horario.horario_sala_turno_inicial_id ? () => {} : editOpen(horario, dia?.id)}
                                                                                >
                                                                                    <MdEdit size={20}/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }) : '')
                        }
                    </>
                }
            </div>

            <Modal size="lg" title="Incluir professor" {...modalProps}>
                <Form onSubmit={() => onSubmitModal()}>
                    <div className={'grid gap-2 grid-cols-12'} >
                        <div className={'col-span-4'}>
                            <div className={'flex border rounded-md justify-center items-center h-[2.8rem]  border-2 px-3 mt-6'}>
                                { checkObject(modalData) ? modalData?.inicio_aula.slice(0, -3)+' - '+modalData?.final_aula.slice(0, -3) : '' }
                            </div>
                        </div>
                        <div className={'col-span-8'}>
                            <InputSelect
                                selected={professorSelected}
                                options={professorOptions}
                                onChangeSelect={(e: any) => setProfessorSelected(e)}
                                placeholder={'Professor'}
                                label={'Professor'}
                            />
                        </div>
                    </div>
                    <div className={'flex justify-end mt-5'}>
                        <Button loading={loadingFormModal} type={'submit'} variant="constancia">Salvar</Button>
                    </div>
                </Form>
            </Modal>
        </PageContainer>
    );
}