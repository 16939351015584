import { ListPageLayout } from "../../components";
import { professoresApi } from "../../services";

export default function Professores() {
    return (
        <ListPageLayout 
            title="Professores"
            creation={{ url: '/professores/cadastro' }}
            edition={{ url: '/professores/cadastro' }}  
            deletion={{ handler: professoresApi.destroy }}
            table={{
                loader: professoresApi.all,
                columns: [
                    { title: 'Id', field: 'id' },
                    { title: 'Nome', field: 'nome' },
                    { title: 'CNPJ', field: 'cnpj' },
                ],
            }}
        />
    )
}
