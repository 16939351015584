import PageContainer from "../../components/container/PageContainer";
import CardBasicExample from "../../components/card/Card";
import './index.css';
import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router';
import Button from "../../components/button/Button";
import {BsExclamationLg} from "react-icons/bs";
import lembretesApi from "../../services/lembretesApi";

export default function Dashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadDataInfo, setLoadDataInfo] = useState();
    async function loadData() {
        const { data, isError } = await lembretesApi.all({perPage: 12});
        setLoadDataInfo(data.data);
        setLoading(false);
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <>
            <PageContainer footer={true} >
                <div className={'lg:flex lg:flex-nowrap '}>
                    {/*<div className={'md:grid grid-cols-2 gap-2 md:w-[30%] '}>*/}
                    <div className={'md:flex '}>
                        <div>
                            <CardBasicExample
                                titulo={'ARTE'}
                                className={'bg-criatividade  justify-center lg:w-[195px] lg:h-[180px] md:w-[fit-content]'}
                                classNameTitle={'text-white'}
                            />
                            <CardBasicExample
                                titulo={'MÚSICA'}
                                className={'bg-confianca md:mr-5 justify-center lg:w-[195px] lg:h-[180px] md:w-[fit-content]'}
                                classNameTitle={'text-white'}
                            />
                        </div>
                        <div>
                            <CardBasicExample
                                titulo={'IDIOMA'}
                                className={'bg-raizes justify-center lg:w-[195px] lg:h-[180px] md:w-[fit-content]'}
                                classNameTitle={'text-white'}
                            />
                            <CardBasicExample
                                titulo={'CORPO MENTE'}
                                className={'bg-conhecimento justify-center lg:w-[195px] lg:h-[180px] md:w-[fit-content]'}
                                classNameTitle={'text-white'}
                            />
                        </div>
                    </div>
                    <div className={'flex md:w-[100%] md:pl-[20px]'}>
                        {
                            loading ?
                                <span className="flex items-center justify-center w-full">
                                  <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                </span>
                                :
                                <CardBasicExample
                                    onClick={() => navigate('/lembretes')}
                                    titulo={'Lembretes'}
                                    className={'bg-wite w-[100%]'}
                                    data={loadDataInfo}
                                />
                        }
                    </div>
                </div>

            </PageContainer>
        </>

    );
}