import { tw, uniqId } from "@matheuscaetano/helprs";

export type CheckboxProps = {
  value?: boolean;
  setValue?: (value: boolean) => void;
  className?: string;
  label?: string;
}

export function Checkbox({
  value,
  setValue,
  className,
  label,
}: CheckboxProps) {
  const id = uniqId();

  return (
    <div className={tw(className, 'flex items-center gap-1')}>
        <input
            onChange={() => setValue && setValue(!value)}
            checked={value}
            type="checkbox"
            value=""
            id={id}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
            htmlFor={id}
            className=""
        >
            {label}
        </label>
    </div>
  );
}
