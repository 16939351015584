import {useEffect, useState} from 'react';

import Logo2Src from '../../../assets/img/logo1.png';
import Title from '../../../components/text/Title';
import Dropdown from '../../../components/dropdown/Dropdown';
import Storage from '../../../helpers/Storage';
import useAppContext from '../../../hooks/useAppContext';
import useModal from '../../../hooks/useModal';

import Modal from '../../../components/modal/Modal';
import Form from '../../../components/form/Form';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';

import {changePassword} from "../../../types";
import {MdOutlineAddCircleOutline} from "react-icons/md";
import {BsCalendar3, BsPersonGear} from "react-icons/bs";
import {TbReportMoney} from "react-icons/tb";
import {GiTakeMyMoney} from "react-icons/gi";
import {IoMdFingerPrint} from "react-icons/io";
import {BiSearchAlt2} from "react-icons/bi";
import {AiTwotoneHome} from "react-icons/ai";
import {useNavigate} from "react-router";

export interface NavbarProps {
  pageTitle?: string;
}

export default function Navbar({ pageTitle }: NavbarProps) {
  const userData = Storage.getUserData();

  const appContext = useAppContext();

  const [dataPassword, setDataPassword] = useState<changePassword>({
    atualPassword: '',
    novoPassword: '',
    repetirPassword: '',
  });
  const navigate = useNavigate();
  const { modalProps, open: openResetPasswordModal } = useModal();
  const { modalProps: searchStudentModalProps, open: openSearchStudentModal } = useModal();
  const { modalProps: searchTeacherModalProps, open: openSearchTeacherModal } = useModal();

  const { modalProps: modalProps2, open, close } = useModal();

  useEffect(() => {
    if (userData) {
      appContext.setUsuario(userData.usuario);
    }
  }, []);

  function handleLogout() {
    Storage.removeApiToken();
    Storage.removeUserData();
    window.location.href = '/';
  }

  async function changePassword(e: any) {
    /*e.preventDefault();
    if (dataPassword.novoPassword != dataPassword.repetirPassword) {
      Alert.error('Senhas São diferentes');
      return;
    }
    //salva a senha
    const {data, isError} = await adminApi.changePassword(dataPassword);
    if (isError) return Alert.error(data);
    Alert.success('Senha alterada com sucesso!');
    close();*/
  }

  return (
    <>
      <nav className="bg-background  flex items-center">
        <aside className="w-[22rem]">
          <img src={Logo2Src} alt="" />
        </aside>
        <main className="flex items-center justify-between flex-1">
          <Title size="lg"></Title>
          <div className={'flex justify-end py-[15px] bg-slate-200 z-10'}>
              <div className="flex items-center gap-2  py-2 px-2 rounded-md cursor-pointer" onClick={() => navigate('/')}>
                  <AiTwotoneHome size={'40'}/>
              </div>
            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md ">
                    <span className="flex flex-col items-start">
                        <MdOutlineAddCircleOutline size={'40'}/>
                    </span>
                  </div>
                }
                items={[
                  { label: '+ ALUNO(A)', onClick: () => navigate('/alunos') },
                  { label: '+ MATRÍCULAS', onClick: () => navigate('/alunos-cursos') },
                  { label: '+ PROFESSOR(A)', onClick: () => navigate('/professores') },
                  { label: '+ AVULSA', onClick: () => navigate('/avulsas') },
                  { label: '+ INTENSIVO', onClick: () => navigate('/intensivos') },
                  // { label: '+ OFICINA', onClick: open },
                  { label: '+ NOVO CURSO', onClick: () => navigate('/cursos') },
                  { label: '+ CLÁUSULAS', onClick: () => navigate('/clausulas') },
                  { label: '+ NOVA SALA', onClick: () => navigate('/salas') },
                ]}
            />
            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                    <span className="flex flex-col items-start">
                        <BsCalendar3 size={'40'}/>
                    </span>
                  </div>
                }
                items={[
                  { label: 'Visualizar Calendário', onClick: () => navigate('/calendario') },
                ]}
            />

            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                <span className="flex flex-col items-start">
                  <TbReportMoney size={'40'}/>
                </span>

                    {/*<FiChevronDown />*/}
                  </div>
                }
                items={[
                  { label: 'Categorias', onClick: () => navigate('/vendas-categorias') },
                  { label: 'Vendas', onClick: () => navigate('/vendas') },
                  { label: 'Vendas Clientes', onClick: () => navigate('/vendas-clientes') },
                ]}
            />
            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                <span className="flex flex-col items-start">
                  <GiTakeMyMoney size={'40'}/>
                </span>

                    {/*<FiChevronDown />*/}
                  </div>
                }
                items={[
                  { label: 'Contas Fixas', onClick: () => navigate('/despesas') },
                  { label: 'Despesas Extras', onClick: () => navigate('/despesas-extras') },
                  { label: 'Repasses', onClick: () => navigate('/repasses') },
                  { label: 'Clientes Ativos', onClick: () => navigate('/alunos-lista') },
                ]}
            />

            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                    <span className="flex flex-col items-start">
                      <IoMdFingerPrint size={'40'}/>
                    </span>

                    {/*<FiChevronDown />*/}
                  </div>
                }
                items={[
                  { label: 'Pesquisar', onClick: open },
                  { label: 'Cadastrar', onClick: open },
                  ]}
            />

            <Dropdown
                trigger={
                  <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                <span className="flex flex-col items-start">
                  <BiSearchAlt2 size={'40'}/>
                </span>

                    {/*<FiChevronDown />*/}
                  </div>
                }
                items={[
                  { label: 'Aluno', onClick: openSearchStudentModal },
                  { label: 'Professor', onClick: openSearchTeacherModal  },
                  // { label: 'Palavra chave', onClick: open  },
                ]}
            />

              <Dropdown
                  trigger={
                      <div className="flex items-center gap-2  py-2 px-3 rounded-md">
                <span className="flex flex-col items-start">
                  <BsPersonGear size={'40'}/>
                </span>

                          {/*<FiChevronDown />*/}
                      </div>
                  }
                  items={[
                      { label: 'Alterar senha', onClick: openResetPasswordModal },
                      { label: 'Sair', onClick: handleLogout  },
                  ]}
              />
          </div>
        </main>
        <Modal {...modalProps2} title='Em Desenvolvimento' size='lg'></Modal>

        <Modal size="lg" title="Alterar Senha" {...modalProps}>
          <Form onSubmit={changePassword}>
            <div className={'p-3'}>
              <Input
                label="Senha"
                inputType='password'
                value={dataPassword.atualPassword}
                setValue={(atualPassword) => setDataPassword({ ...dataPassword, atualPassword })}
              />

              <Input
                className={'mt-4'}
                label="Nova Senha"
                inputType='password'
                value={dataPassword.novoPassword}
                setValue={(novoPassword) => setDataPassword({ ...dataPassword, novoPassword })}
              />

              <Input
                className={'mt-4'}
                label="Repetir Senha"
                inputType='password'
                value={dataPassword.repetirPassword}
                setValue={(repetirPassword) => setDataPassword({ ...dataPassword, repetirPassword })}
              />
            </div>
            <div className={'p-3 flex '}>
              <Button type='submit' >
                Salvar
              </Button>
              <Button onClick={close} className={'ml-5'}>
                Cancelar
              </Button>
            </div>

          </Form>
        </Modal>

        <Modal {...searchStudentModalProps} title='Buscar Alunos' size='lg' onOpen={() => {
          setTimeout(() => {
            document.getElementById('searchStudentModalInputContainer')?.querySelector('input')?.focus();
          }, 100)
        }}>
          <form id='searchStudentModalInputContainer' action='/alunos' method='get'>
            <Input name='q' />

            <br />
            <br />

            <Button variant='constancia' className='w-full' type='submit'>Buscar</Button>
          </form>
        </Modal>

        <Modal {...searchTeacherModalProps} title='Buscar Professores' size='lg' onOpen={() => {
          setTimeout(() => {
            document.getElementById('searchTeacherModalInputContainer')?.querySelector('input')?.focus();
          }, 100)
        }}>
          <form id='searchTeacherModalInputContainer' action='/professores' method='get'>
            <Input name='q' />

            <br />
            <br />

            <Button variant='constancia' className='w-full' type='submit'>Buscar</Button>
          </form>
        </Modal>
      </nav>
    </>
  );
}
