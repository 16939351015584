import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { BiCheck, BiCaretDown } from 'react-icons/bi';
import Str from '../../../helpers/Str';

export interface DropdownItem {
  label: string;
  onClick?: () => void;
}

export interface DropdownProps {
  className?: string;
  trigger?: React.ReactNode;
  items?: DropdownItem[];
}

export default function Dropdown({
  className,
  trigger,
  items,
}: DropdownProps) {
  return (
    <Menu as="div" className={Str.tw(className, 'relative inline-block text-left')}>
      <div>
        <Menu.Button>
          {trigger}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {items?.map((item, i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <button
                    onClick={item.onClick}
                    className={`${
                      active ? 'bg-primary text-in-primary' : ''
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
            
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
