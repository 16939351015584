/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { repasseApi } from '../../services';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Repasse } from '../../models';

const rules = z.object({
  valor: z.string().min(1),
  data: z.string().min('XXXX-XX-XX'.length),
});

export type UseRepasseForm = {
  id?: number | string;
}

type FormType = Repasse & {
};

export function useRepasseForm(props?: UseRepasseForm) {
  const navigate = useNavigate();
  const { form, setForm, errors, register, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: repasseApi.one as any,
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await repasseApi.save(form);
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar repasse');
    }
    Alert.success('Repasse salva com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await repasseApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar repasse');
    }
    setForm(data);
  }

  return {
    form,
    setForm,
    save,
    loading,
    errors, 
    register,
    load,
  }
}
