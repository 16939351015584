/* eslint-disable import/no-anonymous-default-export */
import { Evento } from '../models';
import api, { makeCrudObject } from './api';

export default {
  ...makeCrudObject<Evento>('eventos'),

  async loadCourseEvents(coursesIds: number[], start: string, end: string) {
    const response = await api.get<Evento[]>('/eventos/list-course-dates?courses=' + coursesIds.join(',') + '&start=' + start + '&end=' + end);
    return response.data;
  }
};
