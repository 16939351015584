import { Usuario } from '../types';

export default {
    API_TOKEN: '@token:data',
    USER_DATA: '@user:data',

    set(key: string, value: any) {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        this.setRaw(key, value);
    },

    setRaw(key: string, value: any) {
        localStorage.setItem(key, value);
    },

    get(key: string): string | object | null {
        let value = this.getRaw(key);

        if (value) {
            try {
                value = JSON.parse(value);
                // eslint-disable-next-line no-empty
            } catch (err) {}
        }

        return value;
    },

    getRaw(key: string) {
        return localStorage.getItem(key);
    },

    remove(key: string) {
        localStorage.removeItem(key);
    },

    setApiToken(token: string) {
        this.setRaw(this.API_TOKEN, token);
    },

    getApiToken() {
        return this.getRaw(this.API_TOKEN);
    },

    removeApiToken() {
        this.remove(this.API_TOKEN);
    },

    setUserData(data: { usuario: Usuario}) {
        this.set(this.USER_DATA, data);
    },

    getUserData() {
        return this.get(this.USER_DATA) as { usuario: Usuario} | null;
    },

    removeUserData() {
        this.remove(this.USER_DATA);
    },
}