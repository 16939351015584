import { QueryParams, IHorariosSalasTurnos } from '../types';
import {getAll, create, update, getOne, deleteOne} from './api';

const endpoint = 'horarios-salas-turnos';

export default {
    all: async (params?: QueryParams) => getAll<IHorariosSalasTurnos>({ endpoint, params }),
    one: async (id: string) => getOne<IHorariosSalasTurnos>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<IHorariosSalasTurnos>) => create<IHorariosSalasTurnos>({ endpoint, formData }),
    update: async (id: string, formData: Partial<IHorariosSalasTurnos>) => update<IHorariosSalasTurnos>({ endpoint, formData, id }),
};
