/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { vendaCategoriaApi } from '../../services';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { VendaCategoria } from '../../models';

const rules = z.object({
  nome: z.string().min(1),
});

export type UseVendaCategoriaForm = {
  id?: number | string;
}

type FormType = VendaCategoria & {
};

export function useVendaCategoriaForm(props?: UseVendaCategoriaForm) {
  const navigate = useNavigate();
  const { form, setForm, errors, register, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: vendaCategoriaApi.one as any,
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await vendaCategoriaApi.save(form);
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar categoria');
    }
    Alert.success('Categoria  salva com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await vendaCategoriaApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar categoria');
    }
    setForm(data);
  }

  return {
    form,
    save,
    loading,
    errors, 
    register,
    load,
   
  }
}
