import { UseFormData } from "../../../hooks/useForm"
import { Aluno } from "../../../models"
import { Button, FormContainer, Input, RequiredMessage, Txt } from "../../../components";
import Alert from "../../../helpers/Alert";
import { alunoApi } from "../../../services";
import Str from "../../../helpers/Str";
import { Dt } from "../../../helpers";
import { alunoCursoFinalDate, alunoCursoMonthlyValue, alunoCursoTurnosHorarios, openBase64Pdf } from "../../../functions";
import { useNavigate } from "react-router-dom";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { Tooltip } from 'react-tooltip'


export type Step2Props = UseFormData<Aluno> & {
  matricula?: string | string;
  id?: number | string;
  canEdit?: boolean;
  prevStep: () => void;
}

export default function Step1(props: Step2Props) {
  const navigate = useNavigate();

  async function enviarContrato() {
    if (!props.id || !props.matricula) return;
    const confirm  = await Alert.confirm('O contrato será enviado para assinatura. Tem certeza que deseja continuar?');
    if (! confirm) return;
    props.setLoading(true);
    try {
        await alunoApi.sendContract(props.form.id, props.matricula, makeRequestData());
        props.setLoading(false);
        Alert.success('Contrato enviado com sucesso.');
        navigate('/alunos-cursos');
    } catch(e) {
        props.setLoading(false);
        return Alert.error('Falha enviar contrato');
    }
  }


  async function save() {
    const confirm  = await Alert.confirm('A matrícula será salva. O contrato poderá ser enviado a qualquer momento, porém, os dados não poderão ser alterados. Tem certeza que deseja continuar?');
    if (! confirm) return;
    props.setLoading(true);
    try {
        await alunoApi.alunoCurso(makeRequestData());
        props.setLoading(false);
        Alert.success('Matrícula salva com sucesso');
        navigate('/alunos-cursos');
    } catch(e) {
        props.setLoading(false);
        return Alert.error('Falha ao salvar matrícula');
    }
  }

  function makeRequestData() {
    console.log('REQUESt', props.form.aluno_cursos![0].meses_pagamento);
    return {
      ...props.form,
      responsavel_telefone: Str.addPhoneMask(props.form.responsavel_telefone),
      responsavel_cpf: Str.addCpfCnpjMask(props.form.responsavel_cpf),
      responsaveis: props.form.responsaveis?.filter(responsavel => {
          return !!responsavel.nome?.trim().length;
      }),
      idade: Dt.getAge(props.form.data_nascimento ?? '').toString(),
      nascimento: props.form.data_nascimento?.split('-').reverse().join('/'),
      responsaveis_se_menor: props.form.responsaveis?.map(r => r.nome).filter(n => n).join(', '),
      aluno_cursos: (props.form.dobrar_cursos ? props.form.aluno_cursos : props.form.aluno_cursos?.filter(item => !item.dobra))!.map(alunoCurso => {
          return {
              ...alunoCurso,
              datas_aulas: alunoCurso.datas_aulas?.filter(da => da.date),
              meses_pagamento: alunoCurso.meses_pagamento,
              valor_mes: `${alunoCursoMonthlyValue(alunoCurso, true, { formatar: true })}`,
              observacao: Number(alunoCurso.porcentagem_desconto) > 0 ? (
                  `${alunoCursoMonthlyValue(alunoCurso, false, { formatar: true })} - ${alunoCurso.porcentagem_desconto}% = ${alunoCursoMonthlyValue(alunoCurso, true,  { formatar: true })}`
              ) : '',
              curso_nome: alunoCurso.curso?.descricao,
              curso_plano: alunoCurso.planos?.join(', '),
              dias_semana: alunoCursoTurnosHorarios(alunoCurso).filter((horario) => {
                  return horario.value === alunoCurso.horarios?.find((h) => h.salas_turnos_id === horario.value)?.salas_turnos_id
              }).map((horario) => horario.label).join(', '),

              dias_semana_list: alunoCursoTurnosHorarios(alunoCurso).filter((horario) => {
                  return horario.value === alunoCurso.horarios?.find((h) => h.salas_turnos_id === horario.value)?.salas_turnos_id
              }).map((horario) => horario.label.trim().substr(0, 3)),

              professor_nome: alunoCurso.professor_temp?.nome,
              inicio_plano: alunoCurso.data_inicio.split('-').reverse().join('/'),
              data_fim: alunoCursoFinalDate(alunoCurso).split('/').reverse().join('-'),
              fim_plano: alunoCursoFinalDate(alunoCurso),
              curso: undefined,
              professor_temp: undefined,
          }
      }),
    }
  }

  async function previewContract() {
    Alert.await('Gerando pre-visualização do contrato...');
    const { data } = await alunoApi.downloadPreview(makeRequestData());
    Alert.close();
    openBase64Pdf(data);
  }

  function addDate(i: number) {
    const acs = props.form!.aluno_cursos!;
    acs[i].datas_aulas.push({ title: 'Aula ' + (acs[i].datas_aulas.length + 1) , date: '' });
    props.setForm({
      ...props.form,
      aluno_cursos: acs
    })
  }

  function removeDate(i: number) {
    const acs = props.form!.aluno_cursos!;
    acs[i].datas_aulas.pop();

    props.setForm({
      ...props.form,
      aluno_cursos: acs
    })
  }

  return (
    <FormContainer title="Definir datas das aulas" >
      {props.form!.aluno_cursos!.map((ac, i) => {
        return (
          <div className="my-10" key={i}>
            <Txt variant="title">{ac.curso?.descricao}</Txt>

            <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
              {ac.datas_aulas?.map((d, j) => {
                return (
                  <Input 
                    label={'Aula ' + (j + 1)}
                    tooltip={d.title}
                    value={d.date}
                    disabled={!props.canEdit}
                    setValue={(v) => {
                      const f = props.form;
                      f.aluno_cursos![i].datas_aulas![j].date = v;
                      props.setForm({ ...f });
                    }}
                    type="date"
                  />
                );
              })}

              {props.canEdit && <div className="flex  gap-2 items-center justify-center ">
                <button className="hover:animate-pulse" type="button" onClick={() => removeDate(i)}>
                  <FiMinusCircle color={'#FF6666'} size={30}  />
                </button>
                <button className="hover:animate-pulse" type="button" onClick={() => addDate(i)}>
                  <FiPlusCircle color={'#30694A'} size={30}/>
                </button>
              </div>}
            </div>
          </div>
        );
      })}

      <footer className="col-span-full flex flex-col md:flex-row items-center md:items-end md:justify-between mt-6">
          <RequiredMessage />

          <div className="flex gap-4">
          <Button loading={props.loading} type="button" onClick={props.prevStep}>
              Anterior
            </Button>

            <Button loading={props.loading} type="button" onClick={previewContract}>
              Pré-visualizar contrato
            </Button>

            {!props.canEdit ? (
                <Button loading={props.loading} type="button" onClick={enviarContrato} variant="confianca">
                  Enviar Contrato
                </Button>
            ) :  (
              <Button loading={props.loading} type="button" onClick={save} variant="confianca">
                Gerar Matrícula
              </Button>
            )}
          </div>
      </footer>
    </FormContainer>
  )
}
