import { QueryParams, ISalas } from '../types';
import api from './api';

const endpoint = 'cursos';

export default {
    criarValorUnico: async (dataForm: any) => {
        try {
            const { data } = await api.post('/valores-cursos/unitario', dataForm);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
