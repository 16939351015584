const dias = [
    {
        id: 1,
        name: 'Segunda',
        abreviacao: 'SEG',
        turnos: [],
    },
    {
        id: 2,
        name: 'Terça',
        abreviacao: 'TER',
        turnos: [],
    },
    {
        id: 3,
        name: 'Quarta',
        abreviacao: 'QUA',
        turnos: [],
    },
    {
        id: 4,
        name: 'Quinta',
        abreviacao: 'QUI',
        turnos: [],
    },
    {
        id: 5,
        name: 'Sexta',
        abreviacao: 'SEX',
        turnos: [],
    },
    {
        id: 6,
        name: 'Sábado',
        abreviacao: 'SÁB',
        turnos: [],
    }
];

export default dias;
export const diasAsSelectOptions = dias.map(d => ({ value: d.id, label: d.name }));
