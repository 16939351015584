import React, { useState, useEffect } from 'react';
import useForm from '../../../hooks/useForm';
import { LoginData } from '../../../types';
import Form from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import Title from "../../../components/text/Title";
import ColoredButton from "../../../components/button/ColoredButton";
import authApi from "../../../services/authApi";
import Storage from '../../../helpers/Storage';
import LogoSrc from '../../../assets/img/logo2.png';
export default function Login() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { form, isFormInvalid, handleChange } = useForm<LoginData>({
        validator: (data) => data.password && data.email
    });

    async function handleSubmit() {
        setErrorMessage('');
        setIsLoading(true);

        const { data, isError } = await authApi.login(form as LoginData);

        if (isError) {
            setIsLoading(false);
            setErrorMessage(data.message);
            return;
        }

        Storage.setApiToken(data.token);
        Storage.setUserData({ usuario: data.usuario });
        window.location.href = '/';
    }

    return (
        <div className={'bg-gradient-to-r from-raizes via-criatividade via-cultura via-constancia via-confianca to-conhecimento'}>
            <div className="flex items-center justify-center h-screen p-5">
                <div className="lg:shadow w-full w-max">
                    <Form
                        className="bg-slate-50 shadow lg:shadow-none p-9 grid place-items-center mx-auto lg:w-[26rem]  bg-opacity-40"
                        onSubmit={() => {handleSubmit()}}
                    >
                        <img src={LogoSrc} className="max-w-[250px]" />

                        <Title size="2xl" color="raizes" className="mb-2 ">Bem vindo</Title>
                        <p className="text-md text-constancia">Informe seus dados de acesso.</p>

                        {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}

                        <Input
                            large
                            hideLabel
                            inputType="email"
                            className="my-5"
                            value={form?.email}
                            setValue={(v) => handleChange(v, 'email')}
                        />

                        <Input
                            large
                            hideLabel
                            inputType="password"
                            value={form?.password}
                            setValue={(v) => handleChange(v, 'password')}
                        />

                        <ColoredButton
                            className="mt-6 mb-4"
                            disabled={isFormInvalid}
                            loading={isLoading}
                            type="submit"
                        >
                            Entrar
                        </ColoredButton>

                        <a className="text-confianca hover:text-confianca-100 transition cursor-pointer" href={'/recuperar/senha'}>
                            Esqueceu a senha?
                        </a>
                    </Form>
                </div>
            </div>
        </div>
    );

}