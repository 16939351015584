import { useState } from "react";
import { Button, Checkbox, Input, ListPageLayout, Select, Txt } from "../../components";
import Form from "../../components/form/Form";
import { useAvulsoForm } from "../../hooks";
import { avulsoApi } from "../../services";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

export default function Avulsos() {
  const [id, setId] = useState<number>();

  return (
    <ListPageLayout 
      title="Avulsas"
      deletion={{ handler: avulsoApi.destroy }}
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (avulso) => setId(avulso?.id),
      }}
      table={{
        loader: avulsoApi.all,
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'Nome', field: 'nome' },
            { title: 'E-mail', field: 'responsavel_email' },
            { title: 'CPF', field: 'responsavel_cpf', columnType: 'CPF' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    form,
    register,
    registerList,
    save,
    age,
    curso, 
    cursos,
    horarios,
    quemPaga,
    materiais,
    professores,
    formaPagamento,
  } = useAvulsoForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Select
        required
        label="Curso"
        className="col-span-full lg:col-span-6"
        loading={cursos.loading}
        options={cursos.asSelectOptions}
        {...register('curso_id')}
      />

      <Select
        required
        label="Professor"
        className="col-span-full lg:col-span-6"
        options={professores.asSelectOptions}
        {...register('professor_id')}
      />

      <Input 
        required
        disabled={!form.curso_id || !form.professor_id}
        label="Data"
        type="date"
        className="col-span-full lg:col-span-4"
        {...register('data')}
      />

      <Select
        required
        disabled={!form.curso_id || !form.professor_id || !form.data}
        label="Horário"
        className="col-span-full lg:col-span-8"
        {...register('horario_sala_turno_id')}
        options={horarios}
      />

      <Input 
        required
        label="Responsável Financeiro"
        className="col-span-full lg:col-span-6"
        {...register('responsavel_nome')}
      />

      <Input 
        required
        label="E-mail"
        className="col-span-full lg:col-span-6"
        {...register('responsavel_email')}
      />

      <Input 
        required
        label="Nascimento"
        type="date"
        className="col-span-full lg:col-span-4"
        {...register('responsavel_data_nascimento')}
      />

      <Input 
        required
        label="CPF"
        mask="CPF"
        className="col-span-full lg:col-span-4"
        {...register('responsavel_cpf')}
      />

      <Input 
        required
        label="Telefone"
        mask="cellphone"
        className="col-span-full lg:col-span-4"
        {...register('responsavel_telefone')}
      />

      <Checkbox
        label="Financ. é o Aluno"
        className="col-span-6"
        {...register('aluno_financiador')}
      />

      <Input 
        required
        label="Nome"
        className="col-span-full"
        disabled={form.aluno_financiador}
        {...register('nome')}
      />

      <Input 
        required
        label="Nascimento"
        type="date"
        className="col-span-full lg:col-span-6"
        disabled={form.aluno_financiador}
        {...register('data_nascimento')}
      />

      <Input 
        disabled
        label="Idade"
        className="col-span-full lg:col-span-6"
        value={age}
      />

      <Input 
        disabled
        type="number"
        label="Valor"
        className="col-span-full lg:col-span-5"
        value={curso?.valores?.valor_avulso}
      />

      <Input 
        disabled
        label="Data"
        className="col-span-full lg:col-span-2"
        value="Hoje"
      />

      <Select 
        required
        label="Forma Pagamento"
        className="col-span-full lg:col-span-5"
        {...register('pagamento_forma')}
        options={formaPagamento.asSelectOptions}
      />

      <header className="flex items-center gap-4 col-span-full">
        <Txt variant="title">Materiais</Txt>

        <button 
          type='button' 
          title="Adicionar Material" 
          onClick={materiais.add}
        >
            <FiPlusCircle color={'#30694A'} size={30}/>
        </button>
      </header>

      {form.materiais?.map((material, i) => <div className="col-span-full grid grid-cols-12 gap-4">
        <Select
          required
          label='Material Aula'
          className={'col-span-full lg:col-span-5'}
          options={materiais.asSelectOptions}
          {...registerList('materiais.material_id', i)}
        />

        <Input 
          disabled
          label="Valor"
          className="col-span-full lg:col-span-3"
          value={materiais.getValor(material.material_id)}
        />

        <Select 
          label="Repasse"
          className="col-span-full lg:col-span-3"
          {...registerList('materiais.repasse_tipo', i)}
          options={quemPaga.asSelectOptions}
        />

      <button 
          type='button' 
          title="Adicionar Material"
          className="mt-5"
          onClick={() => materiais.remove(i)}
        >
            <FiMinusCircle color={'darkred'} size={30}/>
        </button>
      </div>)}

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

