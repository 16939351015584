import React from 'react';
import Str from '../../../helpers/Str';

export type TitleProps = React.HTMLAttributes<HTMLHeadingElement> & {
  size?: 'lg' | 'xl' | '2xl';
  color?: 'primary' | 'raizes'
};

export default function Title({
  children,
  className,
  size,
  color,
  ...rest
}: TitleProps) {
  const sizeClass = size === 'lg' ? 'text-lg' :
    size === 'xl' ? 'text-xl' :
      size === '2xl' ? 'text-2xl' : 'text-md';
  const colorClass = color === 'primary' ? 'text-primary' : 'text-raizes';

  return (
    <h1 {...rest} className={Str.tw(
      className, 
      sizeClass,
      'text-raizes',
      'font-bold '
    )}>
      {children}
    </h1>
  );
}