import { ListPageLayout } from "../../components";
import { alunoApi } from "../../services";

export default function Alunos() {
    return (
        <ListPageLayout 
            title="Alunos"
            creation={{ url: '/alunos/cadastro' }}
            edition={{ url: '/alunos/cadastro' }}
            deletion={{ handler: alunoApi.destroy }}
            table={{
                loader: alunoApi.all,
                columns: [
                    { title: 'Id', field: 'id' },
                    { title: 'Nome', field: 'nome' },
                    { title: 'Nascimento', field: 'data_nascimento', columnType: 'date' },
                    { title: 'Responsável', field: 'responsavel_nome' },
                    { title: 'Responsável E-mail', field: 'responsavel_email' },
                    { title: 'Responsável Telefone', field: 'responsavel_telefone', columnType: 'phone' },
                ],
            }}
        />  
    )
}
