import axios from 'axios';

import {PaginatedData, QueryParams, SimpleQueryParams} from '../types';

import Storage from '../helpers/Storage';

export interface PaginatedRequestResponse<T = any> {
    data: PaginatedData
    isError: false;
}

export interface RequestResponse<T = any> {
    data: T;
    isError: false;
}

export interface RequestError<T = any> {
    data: T | { message: string; };
    isError: true;
}

export interface GetAllParams {
    endpoint: string;
    params?: QueryParams;
}

export interface GetOneParams {
    endpoint: string;
    id: number | string;
    params?: SimpleQueryParams;
}

export interface DeleteParams {
    endpoint: string;
    id: number | string;
}
export interface CreateParams<T> {
    endpoint: string;
    formData: Partial<T>;
}

export interface UpdateParams<T> {
    endpoint: string;
    id: number | string;
    formData: Partial<T>;
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    //baseURL: 'https://inovatta.workingtech.com.br/admin/',
    headers: {
        "access-control-allow-origin" : "*",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

api.defaults.headers.common['Authorization'] = 'Bearer ' + Storage.getApiToken();
export default api;

const ajustaUrl = (url: string) => {
    let newUrl = url;
    const urlVal = url.split('?');
    if (urlVal.length > 1) {
        newUrl = urlVal[0];
    }
    return newUrl;
}

export async function getAll<T>({
    params,
    endpoint
}: GetAllParams): Promise<RequestError | PaginatedRequestResponse<T>> {
    const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
    const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
    const search  = params?.search  ? '&search='  + params?.search  : '';
    const relations  = params?.relations  ? '&relations='  + params?.relations  : '';
    const where  = params?.where  ? `&where=${params?.where}`  : '';
    const like = params?.like ? `&like=${params?.like.join(',')}` : '';

    let query = '';

    if (params?.query) {
        Object.keys(params?.query).forEach(key => {
            query += `&${key}=${params?.query![key]}`;
        });
    }

    let finalEndPoint = `${endpoint}?${page}${perPage}${search}${relations}${where}${like}${query}&order=id,desc`;
    /*if (endpoint.split('?').length > 1) {
        finalEndPoint = `${endpoint}&${page}${perPage}${search}${relations}`
    }*/

    try {
        const { data } = await api.get(finalEndPoint);
        return { data, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function listAll<T>({
    endpoint,
    params,
    allConsult
}: {endpoint: string, allConsult?: any, params?: {
    where?: string;
    query?: {
        relations?: string[],
    }
}}): Promise<RequestError | RequestResponse<T[]>> {
    try {
        let finalEndpoint = endpoint
        const where  = params?.where  ? `?where=${params?.where}`  : '';

        if (allConsult){
            finalEndpoint = allConsult
        }

        if (params?.query?.relations) {
            finalEndpoint = `${finalEndpoint}?relations=${params?.query?.relations.join(',')}`
        }

        const { data } = await api.get(`${finalEndpoint}${where}`);
        return { data, isError: false };
    } catch (err: any) {
        let dataErro = err?.response?.data;
        if (err?.response?.data){
            if (err?.response?.data.error){
                dataErro = err?.response?.data.error;
            }
        }
        return {
            data: dataErro ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function getOne<T>({
    endpoint,
    id,
    params
}: GetOneParams): Promise<RequestError | RequestResponse<T>> {
    try {
        const relations  = params?.relations  ? '?relations='  + params?.relations  : '';
        const { data } = await api.get(`${ajustaUrl(endpoint)}/${id}${relations}`);
        return { data: data as T, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function create<T>({
    endpoint,
    formData
}: CreateParams<T>): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.post(ajustaUrl(endpoint), formData);
        return { data, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function deleteOne<T = any>({
    endpoint,
    id
}: DeleteParams): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.delete(`${ajustaUrl(endpoint)}/${id}`);
        return { data: data as T, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function update<T>({
    endpoint,
    id,
    formData
}: UpdateParams<T>): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.put(`${ajustaUrl(endpoint)}/${id}`, formData);
        return { data, isError: false };
    } catch (err: any) {
        console.log(err);
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export function makeCrudObject<T>(endpoint: any) {
    return {
        all: async (params?: QueryParams) => getAll<T>({ endpoint, params }),
        listAll: async (p?: any) => listAll<T>({ endpoint, params: p }),
        one: async (id: string) => getOne<T>({ endpoint, id }),
        destroy: async (id: string | number) => deleteOne({ endpoint, id }),
        create: async (formData: Partial<T>) => create<T>({ endpoint, formData }),
        update: async (id: string | number, formData: Partial<T>) => update<T>({ endpoint, formData, id }),
        save: async (formData: Partial<T>, key = 'id') => {
            if ((formData as any)[key]) {
                return update<T>({ endpoint, formData, id: (formData as any)[key] });
            }
            return create<T>({ endpoint, formData });
        },
    };
}