import { useEffect, useState } from "react";
import { Button, DashCard, Input, ListPageLayout, Select } from "../../components";
import Form from "../../components/form/Form";
import { vendaApi } from "../../services";
import { useVendaForm } from "../../hooks";
import InputCurrency from "../../components/inputCurrency";
import Str from "../../helpers/Str";

export default function Vendas() {
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [info, setInfo] = useState({ quantidade: 0, total: 0 });
  const [id, setId] = useState<number>();

  async function loadInfo() {
    const { data, isError } = await vendaApi.listAll({ 'where': `data,${date},${date}` });
    if (isError ) return;
    let v = 0;
    let t = 0;
    data.forEach((item: any) => {
      v += Number(item.valor);
      t++;
    });
    setInfo({ quantidade: t, total: v});
  }

  useEffect(() => {
    loadInfo();
  }, [date])

  return (
    <ListPageLayout 
      title="Vendas"
      deletion={{ handler: vendaApi.destroy }}
      queries={{
        'where': `data,${date},${date}`
      }}
      header={
        <div className="grid grid-cols-12 gap-4 items-end">
          <Input
              type="date"
              className="col-span-full lg:col-span-2"
              value={date}
              setValue={(e) => setDate(e)}
          />

          <div className="col-span-full lg:col-span-1"></div>

          <div className="grid grid-cols-3 gap-4 col-span-full lg:col-span-9 align-bottom items-center">
            <div></div>
            <DashCard label="Vendas" value={info.quantidade} />
            <DashCard label="Total" value={info.total} type="money" />
          </div>
        </div>
      }
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (item) => setId(item?.id),
      }}
      table={{
        loader: vendaApi.all,
        columns: [
            { title: 'Nome', field: 'nome' },
            { title: 'Produto', field: 'descricao' },
            { title: 'Categoria', field: 'categoria_id' },
            { title: 'Valor', field: 'valor', columnType: 'money' },
            { title: 'Data', field: 'data', columnType: 'date' },
            { title: 'Pagamento', field: 'pagamento_forma' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    form,
    setForm,
    errors,
    register,
    save,
    categorias,
    formaPagamento
  } = useVendaForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input 
        required
        label="Nome"
        className="col-span-full"
        {...register('nome')}
      />

      <Input 
        required
        label="Produto"
        className="col-span-full lg:col-span-7"
        {...register('descricao')}
      />

      <Select 
        required
        label="Categoria"
        className="col-span-full lg:col-span-5"
        {...register('categoria_id')}
        options={categorias.asSelectOptions}
      />

      <InputCurrency
          label="Valor"
          value={form.valor}
          className="col-span-full lg:col-span-4"
          onChange={(value: any) => setForm({ ...form, valor: value })}
          error={errors?.valor}
      />

      <Input 
        required
        label="Data"
        type="date"
        className="col-span-full lg:col-span-4"
        {...register('data')}
      />

      <Select 
        required
        label="Pagamento"
        className="col-span-full lg:col-span-4"
        {...register('pagamento_forma')}
        options={formaPagamento.asSelectOptions}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

