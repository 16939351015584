import Str from "../../../helpers/Str";

export type DashCardProps = {
  label: string;
  value: number | string;
  type?: 'money';
}

export function DashCard({
  label,
  value,
  type,
}: DashCardProps) {
  return (
      <div className="bg-slate-200 rounded-md p-2">
          <h5 className="text-slate-700">{label}</h5>
          <span className="font-semibold">{type === 'money' ? Str.currencyMask(value) : value}</span>
      </div>
  );
}