import PageContainer from "../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router";
import Form from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import Select from 'react-select';
import Alert from "../../../helpers/Alert";
import lembretesApi from "../../../services/lembretesApi";
import {useParams} from "react-router-dom";

export default function LembretesForm() {
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState<any>({
        'descricao': '',
        'categoria': 'Bloco de notas',
        'data': '',
        'hora': '',
    });
    const [selected, setSelected] = useState({ value: 'Bloco de notas', label: 'Bloco de notas' });

    const categoriaOptions = [
        { value: 'Bloco de notas', label: 'Bloco de notas' },
        { value: 'Contas', label: 'Contas' },
        { value: 'Outros', label: 'Outros' },
    ];

    useEffect(() => {
        loadData()
    }, [id]);

    async function loadData() {
        if (id){
            setLoading(true);
            Alert.await(`Carregando dados...`);
            const { data, isError } = await lembretesApi.one(id);
            if (isError) return alert(data.message || 'Falha ao carregar dados.');
            const select = categoriaOptions.filter((dt: any) => dt.value === data.categoria)[0];
            setSelected(select);
            setState(data);
            Alert.close();
        }
        setLoading(false);
    }

    function handleChange(value: string | number | boolean, name: any) {
        setState({ ...state, [name]: value });
    }

    const onChangeSelect = (e: any) => {
        setState({ ...state, ['categoria']: e.value });
        setSelected(e);
        //Alert.success('Cliente deletado com sucesso.');
    }

    async function onSubmit(){
        Alert.await('Enviando informações');
        const { data, isError } =
            id ?
                await lembretesApi.update(id, state)
            :
                await lembretesApi.create(state)
        ;
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }
        Alert.close();
        Alert.success('Lembrete salvo com sucesso');
        navigate('/lembretes');
    }

    const inputSelectStyle = () => ({
        paddingBottom: '5px',
        paddingTop: '6px',
    });

    return loading ?
    <span className="flex items-center justify-center h-7">
      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
        :
    (
        <PageContainer>
            <div className={'flex-row '}>
                <div className={'flex flex-row justify-between p-2 w-full'}>
                    <h1 className={'text-3xl flex items-center'}>Lembrete</h1>
                    <Button onClick={() => navigate('/lembretes')}>Voltar</Button>
                </div>

                <div className={'flex justify-center'}>
                    <Form className={'grid max-w-4xl border p-5'} onSubmit={onSubmit}>
                        <div className="grid gap-4 gap-y-2 grid-cols-1">
                            <Input
                                label="Descrição"
                                className="col-span-10"
                                value={state?.descricao}
                                setValue={v => handleChange(v, 'descricao')}
                            />
                        </div>

                        <div className="md:grid md:grid-cols-10 md:gap-2">
                            <Select
                                isLoading={loading}
                                placeholder={"Categorias"}
                                value={selected}
                                className={
                                    "col-span-4 mt-[1.5rem]"
                                }
                                styles={
                                    {
                                        input: (styles) => ({ ...styles, ...inputSelectStyle() }),
                                    }
                                }
                                options={categoriaOptions}
                                onChange={onChangeSelect}
                            />
                            <Input
                                type={'date'}
                                label="Date"
                                className="col-span-4"
                                value={state?.data}
                                setValue={v => handleChange(v, 'data')}
                            />
                            <Input
                                type={'time'}
                                label="Hora"
                                className="col-span-2"
                                value={state?.hora}
                                setValue={v => handleChange(v, 'hora')}
                            />
                        </div>
                        <div className={'flex justify-end mt-5'}>

                                <Button className={'mr-5 '} onClick={() => navigate('/lembretes')}>Voltar</Button>


                                <Button  type={'submit'} className={'bg-constancia hover:bg-constancia-100 text-white '}>Cadastrar</Button>

                        </div>

                    </Form>
                </div>

            </div>
        </PageContainer>
    )
}