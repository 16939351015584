import { ListPageLayout } from "../../components";
import Str from "../../helpers/Str";
import { alunoApi } from "../../services";

export default function AlunosCursos() {
    return (
        <ListPageLayout 
            title="Matrículas"
            creation={{ url: '/alunos-cursos/cadastro' }}
            edition={{ url: (item: any) => `/alunos-cursos/cadastro/${item.id}/${item.matricula}`   }}
            table={{
                loader: alunoApi.alunoCursoList as any,
                columns: [
                    { title: 'Matrícula', field: 'matricula' },
                    { title: 'Nome', field: 'nome' },
                    { title: 'Cursos', field: 'cursos' },
                ],
            }}
        />
    )
}
