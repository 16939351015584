/* eslint-disable import/no-anonymous-default-export */
export default {
  getAge (dateString: string) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
      return age;
  },

  weekDay(dateString?: string) {
    if (! dateString) return '';
    const days = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];
    const date = new Date(dateString);
    return days[date.getDay()];
  },

  currentYear() {
    const date = new Date();
    return date.getFullYear();
  },

  minusOneDay(dateString: string) {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split('T')[0];
  },
}