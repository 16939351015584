import { useQuery } from "react-query";
import {  VendaCategoria } from "../../models";
import { ISelect } from "../../types";
import { vendaCategoriaApi } from "../../services";

export function useVendasCategorias() {
  const query = useQuery<VendaCategoria[]>('cursos.all', async () => {
    const { data, isError } =  await vendaCategoriaApi.listAll();
    if (isError) throw new Error('Erro ao buscar categorias');
    return data;
  }, {
    initialData: [],
  });

  return {
    categorias: query.data as VendaCategoria[],
    categoriasAsSelectOptions: query.data?.map((c) => ({ value: c.id, label: c.nome })) as ISelect[],
    loading: query.isLoading
  }
}
