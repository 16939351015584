import { Professor } from "../models";
import { diasAsSelectOptions } from "../services/diasApi";

export function diasSemanaFromProfessor(professor?: Professor) {
  if (! professor?.disponibilidade_curso) return [];
  const diasIds: number[] = [];
  professor.disponibilidade_curso?.filter(dc => !dc.desabilitado).forEach(disponibilidadeCurso => {
      if (! disponibilidadeCurso.sala_disponibilidade_turno?.salas_disponibilidade) return;
      diasIds.push(disponibilidadeCurso.sala_disponibilidade_turno.salas_disponibilidade.dia);
  });
  return diasAsSelectOptions.filter(dia => diasIds.includes(dia.value));
}