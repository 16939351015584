import CurrencyInput from "react-currency-input-field";
import Str from "../../helpers/Str";
import {IInputCurrency} from "../../types";
import { tw } from "@matheuscaetano/helprs";

export default function InputCurrency({label, value, onChange, onBlur, className, error}: IInputCurrency){
    return(
        <div className={tw(className, 'col-span-1')}>
            {
                label  ?
                    <label
                        className="font-medium "
                    >
                        {label}
                    </label>
                    :
                    ''
            }

            <CurrencyInput
                id="input-example"
                className={Str.tw(
                    'py-2 px-1',
                    label ? '' : '',
                    'font-medium bg-light border border-border rounded-md outline-none w-full',
                    'focus:bg-background focus:border-confianca transition '
                )}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                name="valor"
                value={value}
                decimalsLimit={2}
                onValueChange={(value, name) => onChange(value, name)}
                onBlur={onBlur}
            />
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </div>
    )
}