/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { clausulaApi } from '../../services';
import { useCursos } from '../useCursos';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Clausula } from '../../models';

const rules = z.object({
  nome: z.string().min(1),
  descricao: z.string().min(1),
});

export type UseClausulaForm = {
  id?: number | string;
}

type FormType = Clausula & {
  cursos_ids: number[],
};

export function useClausulaForm(props?: UseClausulaForm) {
  const navigate = useNavigate();
  const { cursos, loading: loadingCursos, cursosAsSelectOptions }  = useCursos();
  const { form, setForm, errors, register, registerList, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: clausulaApi.one as any,
    initialData: {
      ordem: 1,
    }
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await clausulaApi.save({
      ...form,
    });
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar cláusula');
    }
    Alert.success('Cláusula salva com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await clausulaApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar cláusula');
    }
    setForm({
      ...data,
      cursos_ids: data.cursos_clausulas?.map(c => c.curso_id)
    });
  }

  return {
    form,
    save,
    loading,
    errors, 
    register,
    registerList,
    load,
    cursos: {
      data: cursos,
      asSelectOptions: cursosAsSelectOptions,
      loading: loadingCursos
    },
  }
}
