import { useState } from "react";
import Alert from "../../helpers/Alert";

export type UseStep = {
  initialStep?: number;
  validateNext?: () => false | string | void;
}

export function useStep(props?: UseStep) {
  const [step, setStep] = useState(props?.initialStep ?? 0);

  function change(step: number) {
    if (props?.validateNext) {
      const validation = props?.validateNext();
      if (typeof validation === 'string') {
        Alert.error(validation);
        return;
      } else if (validation === false) return;
    }
    setStep(step);
  }

  return {
    step, 
    setStep,
    nextStep: () => change(step + 1),
    prevStep: () => change(step - 1),
  }
}
