import Str from "../../helpers/Str";
import React from "react";
import {ICardButton} from "../../types";

export default function CardButton({
    id,
    checked,
    selectDisable,
    onSelect,
    text,
    titleHtml,
    data,
}: ICardButton){
    return(
        <div className={Str.tw(
            "w-full group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1",
            selectDisable ? "cursor-not-allowed bg-slate-200 text-gray-900 shadow-lg" : (checked ? " cursor-pointer bg-slate-200 text-gray-900 shadow-lg border-indigo-500 border-2" : "cursor-pointer bg-white")
        )}
             onClick={ () => onSelect(data ? data : id)}
             key={id}
             title={titleHtml}
        >
            {text}
        </div>
    )
}