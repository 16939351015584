import { useState } from "react";
import { Button, Input, ListPageLayout } from "../../components";
import Form from "../../components/form/Form";
import { vendaCategoriaApi } from "../../services";
import { useVendaCategoriaForm } from "../../hooks";

export default function VendasCategorias() {
  const [id, setId] = useState<number>();

  return (
    <ListPageLayout 
      title="Categorias"
      deletion={{ handler: vendaCategoriaApi.destroy }}
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (item) => setId(item?.id),
      }}
      table={{
        loader: vendaCategoriaApi.all,
        columns: [
          { title: 'Nome', field: 'nome' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    register,
    save,
  } = useVendaCategoriaForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input 
        required
        label="Nome"
        className="col-span-full"
        {...register('nome')}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

