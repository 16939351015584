import { QueryParams, Lembretes } from '../types';
import {getAll, create, update, getOne, deleteOne} from './api';

const endpoint = 'lembretes';

export default {
    all: async (params?: QueryParams) => getAll<Lembretes>({ endpoint, params }),
    one: async (id: string) => getOne<Lembretes>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Lembretes>) => create<Lembretes>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Lembretes>) => update<Lembretes>({ endpoint, formData, id }),
};
