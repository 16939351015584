import { useNavigate } from 'react-router-dom';
import { PaginatedRequestResponse, RequestError, RequestResponse } from '../../../services/api';
import { QueryParams } from '../../../types';
import { Button, Modal } from '../..';
import useTable from '../../../hooks/useTable';
import PageContainer from '../../../components/container/PageContainer';
import Table from '../../table/Table';
import { TableColumn1, TableColumn2 } from '../../table/BaseTableRef';
import { concatPath } from '@matheuscaetano/helprs';
import Alert from '../../../helpers/Alert';
import { useSearchParams } from "react-router-dom";
import { useModal } from '../../../hooks';
import { useEffect } from 'react';

export interface ListPageLayoutProps<T = any> {
  title: string;
  actions?: React.ReactNode;
  header?: React.ReactNode | ((data?: T[]) => React.ReactNode);
  unpaginated?: boolean;
  queries?: {
    [key: string]: string
  }
  registerModal?: {
    label?: string;
    hideCreationButton?: boolean;
    form: React.ReactNode;
    avoidRender?: boolean;
    onOpen?: (data?: T) => void;
  },
  creation?: {
    label?: string;
    url: string;
  },
  edition?: {
    url: string | ((data: T) => string);
  },
  deletion?: {
    handler: (id: string | number) => Promise<RequestError<any> | RequestResponse<any>>;
  },
  table: {
    columns: (TableColumn1<T> | TableColumn2<T>)[];
    loader: (params?: QueryParams | undefined) => Promise<RequestError<any> | PaginatedRequestResponse<T>>;
  }
}

export default function ListPageLayout<T = any>({
  title,
  creation,
  header,
  edition,
  registerModal,
  deletion,
  queries,
  table,
  actions,
  unpaginated = false
}: ListPageLayoutProps<T>) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { open: openCreationModal, modalProps: registerModalProps } = useModal();

  const { tableProps, loadData } = useTable<T>({ 
    denyInitialLoad: true,
    dataLoader: async (params) => {
      const q = searchParams.get("q");
      if (q) {
        params = { ...params, like: ['nome', q] };
      }

      const { data, isError } = await table.loader({...params, query: (queries ?? {})} as any);
      if (isError) {
        Alert.error(data.message || data.error || 'Falha ao carregar dados.');
        return;
      }
      return data as any;
    }
  });

  function onEdit(data: T) {
    if (edition) {
      navigate(typeof edition.url === 'string' ? ('/' + concatPath(edition.url, (data as any).id)) : edition.url(data));
      return;
    }

    if (registerModal) {
      registerModal.onOpen?.(data);
      if (!registerModal.avoidRender) {
        openCreationModal();
      }
      return;
    }
  }

  function onOpen() {
    if (creation) {
      navigate(creation.url);
      return;
    }

    if (registerModal) {
      registerModal.onOpen?.();
      openCreationModal();
      return;
    }
  }

  useEffect(() => {
    loadData({ query: queries });
  }, [queries]);

  return (
    <PageContainer menuTitle={title}>
      <div className={'flex flex-col'}>
        <div className={'flex flex-row justify-between p-2 w-full'}>
            <h1 className={'text-3xl flex items-center'}>{title}</h1>
            <div>
                {actions}
                {!!(creation || (registerModal && !registerModal.hideCreationButton)) && <Button
              variant='constancia'
              onClick={onOpen}
            >
              {creation?.label ?? 'Novo'}
            </Button>}
            </div>
        </div>

        <div className={'p-2 w-full'}>
          {typeof header === 'function' ? header(unpaginated ? tableProps.data as any : tableProps?.data?.data) : header}
        </div>

        <div className={'mt-5 '}>
            <Table
                {...tableProps}
                unpaginated={unpaginated}
                columns={table.columns}
                data={unpaginated ? tableProps as any : tableProps.data}
                onEdit={edition || registerModal ? onEdit : undefined}
                onDelete={deletion ? async (data) => {
                  const confirm = await Alert.confirm('Deseja Excluir?', 'Tem certeza que deseja excluir?');
                  if (! confirm) return;
                  Alert.await('Excluindo...');
                  const response = await deletion.handler(data.id);
                  Alert.close();
                  if (response.isError) {
                    Alert.error(response.data.message || 'Falha ao excluir');
                    return;
                  }
                  Alert.success('Excluído com sucesso');
                  loadData();
                } : undefined}
            />
        </div>
      </div>

      {!!registerModal && <Modal 
        {...registerModalProps} 
        size='xl' 
        title={registerModal?.label ?? 'Novo'}
      >
        {registerModal.form}
      </Modal>}
    </PageContainer>
  );
}