import React, {useState} from "react";
import CardButton from "../cardButton";
import InputSelect from "../inputSelect";
import {ITurnosComponent} from "../../types";
import Obj from "../../helpers/Obj";

export default function TurnosComponent({arraySelectedDias, setArraySelectedDias, optionsTurnos, mult}: ITurnosComponent) {

    //let arr = arraySelectedDias.filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }); // check if there is any occurence of the item in whole array
    const [turnosAnteriores, setTurnosAnteriores] = useState(arraySelectedDias);
    const onChangeSelect = (e: any, id: number) => {
        const array = [...arraySelectedDias]

        const val = array.findIndex((sd: { id: number; }) => sd.id === id);

        const turno = Obj.copy(e);

        if (mult){
            turno.map((tr: any) => {
                const valFilter = array[val].turnos.filter((tur:any) => tur.value === tr.value);
                if (!valFilter.length) {
                    array[val].turnos.push(tr);
                }else{
                    array[val].turnos = valFilter;
                }

                /*array[val].turnos.map((arrdt: any) => {
                    if (arrdt.value === tr.value) {
                        console.log('iguais')
                    }else{
                        diferentes = true;
                        if (diferentes){
                            array[val].turnos.push(tr);
                        }

                    }
                });*/
            });
            //console.log(array[val])
            setArraySelectedDias(array)
            return;
        }

        array[val].turnos = [...turno];
        if (e.length > 1) {
            const index = array[val].turnos.findIndex((sd: { label: string; }) => sd.label === 'Todos');
            if (index >= 0){
                array[val].turnos = e.filter((ef: any) => ef.value !== e[index].value);

            }
        }
        setArraySelectedDias(array)
    }

    return (
        <>
            {
                arraySelectedDias.map((aSD: any) => {

                    const setTurnosData = new Set();
                    const turnos = aSD.turnos.filter((turno: any) => {
                        const duplicatedPerson = setTurnosData.has(turno.value);
                        setTurnosData.add(turno.value);
                        return !duplicatedPerson;
                    });

                    /*let turnos = aSD.turnos.filter(
                        function(item: any, index: number, arr: string | any[]){
                            return arr.indexOf(item, index + 1) === -1;
                        });*/

                    return (
                        <div className="grid grid-cols-6 gap-4 ">
                            <div className={'col-span-1 flex items-end'}>
                                <CardButton text={aSD.abreviacao} onSelect={() => {
                                    ""
                                }}/>
                            </div>

                            <div className={'col-span-5 items-start'}>
                                <InputSelect
                                    key={`${aSD.id}-turno`}
                                    selected={turnos}
                                    className={'mt-[1rem]'}
                                    loading={false}
                                    options={optionsTurnos}
                                    onChangeSelect={(e: any) => onChangeSelect(e, aSD.id)}
                                    placeholder={'Turnos'}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )


}