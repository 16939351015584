import { useQuery } from "react-query";
import { Material } from "../../models";
import materiaisApi from "../../services/materiaisApi";
import { mapMateriaisToSelectOptions } from "../../functions";

export function useMateriais() {
  const query = useQuery<Material[]>('materiais.all', async () => {
    const { data, isError } = await materiaisApi.listAll();
    if (isError) throw new Error('Erro ao buscar materiais');
    return data;
  }, {
    initialData: [],
  });

  return {
    materiais: query.data as Material[],
    materiaisAsSelectOptions: mapMateriaisToSelectOptions(query.data as Material[]),
    loading: query.isLoading
  }
}
