import Str from "../../helpers/Str";
import React from "react";
import {ISelectDias} from "../../types";
import CardButton from "../cardButton";
import diasApi from "../../services/diasApi";

export default function SelectDias({
    selectedDias,
    setSelectedDias
}: ISelectDias){

    const dias = diasApi;

    function onSelectDias(diaId: number | string) {
        let info = [...selectedDias];
        if (info.includes(diaId)){
            info =  info.filter((i) => i != diaId);
        }else{
            info.push(diaId);
        }
        setSelectedDias(info);
    }
    return(
        <>
            <div className="flex items-center mt-4">
                <h4 className="text-1xl font-medium text-gray-900">DISPONIBILIDADE</h4>
            </div>
            <div className="grid grid-cols-6 gap-4 mt-2">
                {
                    dias.map((dia: any, index) => {
                        return(
                            <CardButton
                                id={dia.id}
                                checked={selectedDias.includes(dia.id)}
                                onSelect={onSelectDias}
                                text={dia.abreviacao}
                                selectDisable={false}
                            />
                            /*<div className={Str.tw(
                                "group cursor-pointer relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1",
                                selectedDias.includes(dia.id) ? "bg-slate-200 text-gray-900 shadow-lg border-indigo-500 border-2" : "bg-white"
                            )}
                                 onClick={ () => onSelectDias(dia.id)}
                                 key={dia.id}
                            >
                                {dia.abreviacao}
                            </div>*/
                        )
                    })
                }
            </div>
        </>
    )
}