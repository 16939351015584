import { useEffect, useLayoutEffect, useState } from 'react';

export * from '../../../hooks/useStep';

export type StepProps = {
  step: number;
  steps:  React.ReactNode[];
  footer?: React.ReactNode;
}

export function Step({
  step,
  steps,
  footer,
}: StepProps) {
  const [renderedSteps, setRenderedSteps] = useState({} as { [key: number]: React.ReactNode });

  useLayoutEffect(() => {
    if (renderedSteps.hasOwnProperty(step)) {
      return;
    }

    setRenderedSteps({
      ...renderedSteps,
      [step]: steps[step]
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    setRenderedSteps({
      ...renderedSteps,
      [step]: steps[step]
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  return (
    <div>
      {renderedSteps[step]}

      <footer>
        {footer}
      </footer>
    </div>
  );
}