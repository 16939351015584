/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { despesaExtraApi } from '../../services';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { DespesaExtra } from '../../models';

const rules = z.object({
  nome: z.string().min(1),
  valor: z.string().min(1),
  data: z.string().min('XXXX-XX-XX'.length),
});

export type UseDespesaExtraForm = {
  id?: number | string;
}

export function useDespesaExtraForm(props?: UseDespesaExtraForm) {
  const navigate = useNavigate();
  const { form, setForm, errors, register, setLoading, loading, validateWithZod } = useForm<DespesaExtra>({
    loader: despesaExtraApi.one as any,
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await despesaExtraApi.save({
      ...form,
    });
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar despesa');
    }
    Alert.success('despesa salva com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await despesaExtraApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar despesa');
    }
    setForm(data);
  }

  return {
    form,setForm,
    save,
    loading,
    errors, 
    register,
    load,
  }
}
