import { QueryParams, ISalas } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'salas';

export default {
    all: async (params?: QueryParams) => getAll<ISalas>({ endpoint, params }),
    listAll: async () => listAll<ISalas>({ endpoint }),
    one: async (id: string) => getOne<ISalas>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<ISalas>) => create<ISalas>({ endpoint, formData }),
    update: async (id: string, formData: Partial<ISalas>) => update<ISalas>({ endpoint, formData, id }),
    getSalaHorarios: async (dataSearch: any) =>{
        try {
            const sala_id = dataSearch.sala_id ? 'sala_id='+dataSearch.sala_id : '';
            const dia_id = dataSearch.dia_id ? '&dia_id='+dataSearch.dia_id : '';
            const turno_id = dataSearch.turno_id ? '&turno_id='+dataSearch.turno_id : '';
            const url = `${endpoint}/horarios?${sala_id}${dia_id}${turno_id}&order=id,asc`
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    getSalasDisponibilidades: async (dataSearch: any) =>{
        try {
            const sala_id = dataSearch.sala_id ? 'sala_id='+dataSearch.sala_id : '';
            const url = `${endpoint}/disponibilidades?${sala_id}&order=id,asc`
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
