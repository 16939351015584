/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { mapProfessoresToSelectOptions, turnosHorarios } from '../../functions';
import { Dt } from '../../helpers';
import { intensivoApi } from '../../services';
import { useCursos } from '../useCursos';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { Intensivo } from '../../models';
import { useMateriais } from '../useMateriais';
import { ArrElement } from '../../types';
import dias from '../../services/diasApi';

const rules = z.object({
  responsavel_nome: z.string().min(1),
  responsavel_cpf: z.string().min(11, { message: 'CPF inválido' }),
  responsavel_telefone: z.string().min(11, { message: 'Telefone inválido' }),
  responsavel_data_nascimento: z.string().min('XXXX-XX-XX'.length),
  responsavel_email: z.string().email({ message: 'Email inválido' }),

  nome: z.string().min(1),
  data_nascimento: z.string().min('XXXX-XX-XX'.length),

  pagamento_forma: z.string().min(1),

  curso_id: z.number().min(1),
  professor_id: z.number().min(1),
});

export type UseIntensivoForm = {
  id?: number | string;
}

type FormType = Intensivo & {
  materiais: Intensivo['materiais'] & {
    repasse_tipo?: string;
  }
};

export function useIntensivoForm(props?: UseIntensivoForm) {
  const navigate = useNavigate();
  const { materiais, materiaisAsSelectOptions } = useMateriais();
  const { cursos, loading: loadingCursos, cursosAsSelectOptions }  = useCursos();
  const { form, setForm, errors, register, registerList, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: intensivoApi.one as any,
  });

  const curso = cursos.find((c) => c.id === form.curso_id);
  const professor = curso?.professores?.find((p) => p.id === form.professor_id);

  const age = form.data_nascimento ? Dt.getAge(form.data_nascimento) : '';

  const horariosValidos = (form.horarios ?? []).filter(horario => horario.data && horario.horario_sala_turno_id);


  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  useEffect(() => {
    if (! form.aluno_financiador) return;
    setForm({
      ...form,
      nome: form.responsavel_nome,
      data_nascimento: form.responsavel_data_nascimento,
    });
  }, [form.aluno_financiador]);

  useEffect(() => {
    if (! form.aluno_financiador) return;
    if (form.nome !== form.responsavel_nome || form.data_nascimento !== form.responsavel_data_nascimento) {
      setForm({
        ...form,
        responsavel_nome: form.nome,
        responsavel_data_nascimento: form.data_nascimento
      });
    }
  }, [form.nome, form.data_nascimento]);

  useEffect(() => {
    if (! form.aluno_financiador) return;
    if (form.nome !== form.responsavel_nome || form.data_nascimento !== form.responsavel_data_nascimento) {
      setForm({
        ...form,
        nome: form.responsavel_nome,
        data_nascimento: form.responsavel_data_nascimento
      });
    }
  }, [form.responsavel_nome, form.responsavel_data_nascimento]);

  async function save() {
    if (!validateWithZod(rules)) return;

    if (!horariosValidos.length) {
      Alert.error('Cadastre ao menos um horário para este curso.', 'Cadastre um Horário');
      return;
    }

    setLoading(true);
    const response = await intensivoApi.save({
      ...form,
      materiais: form.materiais?.filter(material => material.material_id),
      horarios: horariosValidos,
    });
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar aluno');
    }
    Alert.success('Aluno salvo com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await intensivoApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar aluno');
    }
    data.materiais = data.materiais?.map(material => {
      return {
        ...material,
        repasse_tipo: material.repasse_id ? 'Professor' : 'Espaço',
      }
    })
    setForm(data);
  }

  function addMaterial() {
    setForm({
      ...form,
      materiais: [
        ...(form.materiais ?? []),
        {
          material_id: undefined,
          quem_paga: 'Espaço'
        } as any
      ]
    });
  }

  function removeMaterial(index: number) {
    setForm({
      ...form,
      materiais: [
        ...form.materiais!.slice(0, index),
        ...form.materiais!.slice(index + 1)
      ]
    });
  }

  function materialValor(materialId: number) {
    const material = materiais.find((m) => m.id === materialId);
    if (! material) return 0;
    return material?.valor ?? 0;
  }

  function addHorario() {
    setForm({
      ...form,
      horarios: [
        ...(form.horarios ?? []),
        {
          horario_sala_turno_id: undefined,
          data: undefined,
        } as any
      ]
    });
  }

  function removeHorario(index: number) {
    setForm({
      ...form,
      horarios: [
        ...form.horarios!.slice(0, index),
        ...form.horarios!.slice(index + 1)
      ]
    });
  }

  function calcSelectOptions(horario: ArrElement<Intensivo['horarios']>) {
    if (! horario.data) return [];
    return turnosHorarios(professor, [dias.find(d => d.id === (new Date(horario.data!).getDay() + 1))?.id!]);
  }

  return {
    form,
    save,
    curso,
    professor,
    age,
    loading,
    errors, 
    register,
    horariosValidos,
    registerList,
    load,
    quemPaga: {
      asSelectOptions: [
        {value: 'Espaço', label: 'Espaço'},
        {value: 'Professor',  label: 'Professor'},
      ]
    },
    cursos: {
      data: cursos,
      asSelectOptions: cursosAsSelectOptions,
      loading: loadingCursos
    },
    horarios: {
      calcSelectOptions,
      add: addHorario,
      remove: removeHorario,
    },
    materiais: {
      data: materiais,
      asSelectOptions: materiaisAsSelectOptions,
      add: addMaterial,
      remove: removeMaterial,
      getValor: materialValor,
    },
    professores: {
      asSelectOptions: mapProfessoresToSelectOptions(curso?.professores),
    },
    formaPagamento: {
      asSelectOptions: [
        {value: 'Pix',      label: 'Pix'},
        {value: 'Boleto',   label: 'Boleto'},
        {value: 'Cartão',   label: 'Cartão'},
        {value: 'Dinheiro', label: 'Dinheiro'},
      ],
    }
  }
}
