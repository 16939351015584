/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { vendaClienteApi } from '../../services';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { VendaCliente } from '../../models';
import { useVendasCategorias } from '../useVendasCategorias';

const rules = z.object({
  aluno_id: z.number().min(1),
  valor: z.string().min(1),
  descricao: z.string().min(1),
  categoria_id: z.number().min(1),
  data: z.string().min('XXXX-XX-XX'.length),
  pagamento_forma: z.string().min(1),
  pagamento_data: z.string().min('XXXX-XX-XX'.length),
  pagamento_tipo_notificacao: z.string().min(1),
});

export type UseVendaClienteForm = {
  id?: number | string;
}

type FormType = VendaCliente & {
};

export function useVendaClienteForm(props?: UseVendaClienteForm) {
  const navigate = useNavigate();
  const { categorias, categoriasAsSelectOptions } = useVendasCategorias();
  const { form, setForm, errors, register, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: vendaClienteApi.one as any,
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await vendaClienteApi.save(form);
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar venda');
    }
    Alert.success('Venda  salva com sucesso');
    navigate(0);
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await vendaClienteApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar venda');
    }
    setForm(data);
  }

  return {
    form,
    setForm,
    save,
    loading,
    errors, 
    register,
    load,
    formaPagamento: {
      asSelectOptions: [
        {value: 'Pix',      label: 'Pix'},
        {value: 'Boleto',   label: 'Boleto'},
        {value: 'Cartão',   label: 'Cartão'},
        {value: 'Dinheiro', label: 'Dinheiro'},
      ],
    },
    categorias: {
      data: categorias,
      asSelectOptions: categoriasAsSelectOptions
    }
  }
}
