export function openBase64Pdf(base64Pdf: string) {
  const splitted = base64Pdf.split('base64,');

  if (splitted.length > 1) {
      base64Pdf = splitted[1];
  }

  const bytes = atob(base64Pdf);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
      out[length] = bytes.charCodeAt(length);
  }

  const blob =  new Blob([out], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url);
}