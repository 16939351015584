import { z } from "zod";
import { useNavigate } from "react-router";
import { FormContainer, PageContainer, Button, RequiredMessage, Input, Txt, Select, Checkbox } from "../../../components";
import { alunoApi } from "../../../services";
import Alert from "../../../helpers/Alert";
import { useForm } from "../../../hooks";
import { Dt } from "../../../helpers";
import { useState } from "react";

const rules = {
    responsavel_nome: z.string().min(1),
    responsavel_cpf: z.string().min(11, { message: 'CPF inválido' }),
    responsavel_telefone: z.string().min(11, { message: 'Telefone inválido' }),
    responsavel_data_nascimento: z.string().min('XXXX-XX-XX'.length),
    responsavel_email: z.string().email({ message: 'Email inválido' }),
    responsavel_profissao: z.string().min(1),
    responsavel_nacionalidade: z.string().min(1),
    responsavel_endereco: z.string().min(1),
    responsavel_bairro: z.string().min(1),
    responsavel_cep: z.string().min(8, { message: 'CEP inválido' }),
    responsavel_cidade: z.string().min(1),
    responsavel_uf: z.string().min(2, { message: 'UF inválido' }),

    nome: z.string().min(1),
    data_nascimento: z.string().min('XXXX-XX-XX'.length),
};

const responsaveisRules = z.tuple([z.object({
    nome: z.string().min(1),
    telefone: z.string().min(1),
})]).rest(z.any());

export default function AlunoForm() {
    const [loading, setLoading] = useState({
        cep: false,
        cpf: false
    })

    const navigate = useNavigate();
    const useFormData = useForm({ 
        loader: alunoApi.one,
        beforeFormChange: (newForm) => {
            if (newForm.aluno_financiador) {
                newForm.nome = newForm.responsavel_nome;
                newForm.data_nascimento = newForm.responsavel_data_nascimento;
            }

            if (!newForm?.responsaveis?.length) {
                newForm.responsaveis = [
                    { nome: '', telefone: '', parentesco: '' },
                    { nome: '', telefone: '', parentesco: '' },
                ]
            }

            if (newForm.responsaveis.length === 1) {
                newForm.responsaveis.push({ nome: '', telefone: '', parentesco: '' });
            }

            return newForm;
        },
        initialData: {
            responsavel_nacionalidade: 'Brasileiro(a)',
            responsaveis: [
                { nome: '', telefone: '', parentesco: '' },
                { nome: '', telefone: '', parentesco: '' },
            ]
        },
    });

    const age = useFormData.form.data_nascimento ? Dt.getAge(useFormData.form.data_nascimento) : '';
    const fet = typeof age !== 'number' ? '' : age >= 18 ? 'Maior de idade' : 'Menor de idade';
    const isCPFValid = useFormData.form.responsavel_cpf?.length === 11;
    const isCepValid = useFormData.form.responsavel_cep?.length === 8;

    async function searchResponsavelFinanceiro() {
        if (!isCPFValid) return;
        setLoading({ ...loading, cpf: true });
        const { data } = await alunoApi.searchByResponsavelCpf(useFormData.form.responsavel_cpf!);
        if (!Array.isArray(data) || !data.length) return;
        const aluno = data[0];
        useFormData.setForm({ 
            ...useFormData.form, 
            responsavel_rg: aluno.responsavel_rg || '',
            responsavel_telefone: aluno.responsavel_telefone || '',
            responsavel_nome: aluno.responsavel_nome || '',
            responsavel_data_nascimento: aluno.responsavel_data_nascimento || '',
            responsavel_nacionalidade: aluno.responsavel_nacionalidade || '',
            responsavel_estado_civil: aluno.responsavel_estado_civil || 'Solteiro(a)',
            responsavel_email: aluno.responsavel_email || '',
            responsavel_profissao: aluno.responsavel_profissao || '',
            responsavel_cep: aluno.responsavel_cep || '',
            responsavel_endereco: aluno.responsavel_endereco || '',
            responsavel_bairro: aluno.responsavel_bairro || '',
            responsavel_cidade: aluno.responsavel_cidade || '',
            responsavel_uf: aluno.responsavel_uf || '',
        });
        setLoading({ ...loading, cpf: false });
    }

    function searchCEP() {
        if (!isCepValid) return;
        setLoading({ ...loading, cep: true });
        fetch('https://viacep.com.br/ws/'+ useFormData.form.responsavel_cep + '/json')
            .then(response => response.json())
            .then(data => {
                if (data.erro) {
                    useFormData.setForm({
                        ...useFormData.form,
                        responsavel_endereco: '',
                        responsavel_bairro: '',
                        responsavel_cidade: '',
                        responsavel_uf: '',
                    });
                    return;
                };
                useFormData.setForm({
                    ...useFormData.form,
                    responsavel_endereco: data.logradouro,
                    responsavel_bairro: data.bairro,
                    responsavel_cidade: data.localidade,
                    responsavel_uf: data.uf,
                })
            })
            .catch(err => console.error(err))
            .finally(() => setLoading({ ...loading, cep: false }));
    }

    async function handleSubmit() {
        let valRules = (Number(age) < 18) ? { ...rules, responsaveis: responsaveisRules } : rules;
        if (! useFormData.validateWithZod(z.object(valRules))) return;

        useFormData.setLoading(true);
        const response = await alunoApi.save({
            ...useFormData.form,
        });
        useFormData.setLoading(false);
        if (response.isError) {
            return Alert.error(response.data.message || 'Falha ao salvar aluno');
        }
        Alert.success('Aluno salvo com sucesso');
        navigate('/alunos');
    }

    return (
        <PageContainer>
            <FormContainer title="Cadastro de aluno" onSubmit={handleSubmit}>
                <div className="grid gap-4 md:grid-cols-2">
                    <section className="grid grid-cols-6 gap-4">
                        <Txt variant="title" className="col-span-full">Dados do Responsável Financeiro</Txt>

                        <Input.WithSearchButton 
                            required
                            label="CPF"
                            mask="CPF"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_cpf')}
                            searchButton={{
                                disabled: !isCPFValid,
                                onClick: () => searchResponsavelFinanceiro(),
                                loading: loading.cpf,
                            }}
                        />
                    
                        <Input 
                            label="RG"
                            className="col-span-full lg:col-span-2"
                            maxLength={14}
                            {...useFormData.register('responsavel_rg')}
                        />
                    
                        <Input 
                            required
                            label="Telefone"
                            mask="cellphone"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_telefone')}
                        />

                        <Input 
                            required
                            label="Responsável Financeiro"
                            className="col-span-full"
                            maxLength={40}
                            {...useFormData.register('responsavel_nome')}
                        />
                    
                        <Input 
                            required
                            label="Nascimento"
                            type="date"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_data_nascimento')}
                        />
                    
                        <Input 
                            required
                            label="Nacionalidade"
                            maxLength={14}
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_nacionalidade')}
                        />
                    
                        <Select 
                            label="Estado Civil"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_estado_civil')}
                            options={[
                                {value: 'Solteiro(a)',   label: 'Solteiro(a)'},
                                {value: 'Casado(a)',     label: 'Casado(a)'},
                                {value: 'Divorciado(a)', label: 'Divorciado(a)'},
                                {value: 'Viúvo(a)',      label: 'Viúvo(a)'},
                                {value: 'União Estável',      label: 'União Estável'}
                            ]}
                        />
                    
                        <Input 
                            required
                            label="E-mail"
                            className="col-span-full lg:col-span-3"
                            maxLength={26}
                            {...useFormData.register('responsavel_email')}
                        />
                    
                        <Input 
                            required
                            label="Profissão"
                            className="col-span-full lg:col-span-3"
                            maxLength={22}
                            {...useFormData.register('responsavel_profissao')}
                        />

                        <Input.WithSearchButton 
                            required
                            label="CEP"
                            mask="CEP"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_cep')}
                            searchButton={{
                                disabled: !isCepValid,
                                onClick: () => searchCEP(),
                                loading: loading.cep,
                            }}
                        />
                    
                        <Input 
                            required
                            label="Endereço"
                            className="col-span-full lg:col-span-4"
                            {...useFormData.register('responsavel_endereco')}
                        />
                    
                        <Input 
                            required
                            label="Bairro"
                            className="col-span-full lg:col-span-3"
                            {...useFormData.register('responsavel_bairro')}
                        />

                        <Input 
                            required
                            label="Cidade"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register('responsavel_cidade')}
                        />

                        <Input 
                            required
                            label="UF"
                            mask="UF"
                            className="col-span-full lg:col-span-1"
                            {...useFormData.register('responsavel_uf')}
                        />
                    </section>

                    <section className="grid grid-cols-6 gap-4 place-content-start">
                        <Txt variant="title" className="col-span-full mb-2 mt-1">Dados do Aluno</Txt>
                    
                        <div className="col-span-full flex items-center justify-between">
                            <Checkbox
                                className="mt-7"
                                label="Financ. é o Aluno"
                                {...useFormData.register('aluno_financiador')}
                            />
                        </div>
                    
                        <Input 
                            required
                            label="Nome"
                            className="col-span-full"
                            {...useFormData.register(useFormData.form.aluno_financiador ? 'responsavel_nome' : 'nome')}
                        />
                    
                        <Input 
                            required
                            label="Nascimento"
                            type="date"
                            className="col-span-full lg:col-span-2"
                            {...useFormData.register(useFormData.form.aluno_financiador ? 'responsavel_data_nascimento' : 'data_nascimento')}
                        />
                    
                        <Input 
                            disabled
                            label="Idade"
                            className="col-span-full lg:col-span-2"
                            value={age}
                        />
                    
                        <Input
                            disabled
                            label="Faixa Etária"
                            className="col-span-full lg:col-span-2"
                            value={fet}
                        />

                        <Input
                            required={Number(age) < 18}
                            className="col-span-full lg:col-span-2"
                            label={"Responsável 1"}
                            {...useFormData.registerList('responsaveis.nome', 0)}
                        />
                        <Input
                            required={Number(age) < 18}
                            mask={'phone'}
                            className="col-span-full lg:col-span-2"
                            label={"Telefone "}
                            {...useFormData.registerList('responsaveis.telefone', 0)}
                        />
                        <Input
                            className="col-span-full lg:col-span-2"
                            label={"Parentesco "}
                            {...useFormData.registerList('responsaveis.parentesco', 0)}
                        />

                        <Input
                            className="col-span-full lg:col-span-2"
                            label={"Responsável 2"}
                            {...useFormData.registerList('responsaveis.nome', 1)}
                        />
                        <Input
                            mask={'phone'}
                            className="col-span-full lg:col-span-2"
                            label={"Telefone "}
                            {...useFormData.registerList('responsaveis.telefone', 1)}
                        />
                        <Input
                            className="col-span-full lg:col-span-2"
                            label={"Parentesco "}
                            {...useFormData.registerList('responsaveis.parentesco', 1)}
                        />
                    </section>
                </div>

                <footer className="col-span-full flex flex-col md:flex-row items-center md:items-end md:justify-between mt-6">
                    <RequiredMessage />

                    <div className="flex gap-4">
                        <Button loading={useFormData.loading} type="submit" variant="confianca">
                            Cadastrar
                        </Button>
                    </div>
                </footer>
            </FormContainer>
        </PageContainer>
    );
}