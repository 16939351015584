import {RadioGroup} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import categoriasApi from "../../services/categoriaApi";
import {checkCategorias} from "../../types";

export default function CheckCategorias({
  value,
  onChange,
  categorias
}: checkCategorias) {
    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return(
        <>
            <div className="flex items-center mt-4">
                <h4 className="text-1xl font-medium text-gray-900">CATEGORIA</h4>
            </div>
            <RadioGroup value={value} onChange={onChange} className="mt-2">
                <div className="grid grid-cols-4 gap-4">
                    {
                        categorias.map((categoria: any) => (
                            <RadioGroup.Option
                                key={categoria.id}
                                value={categoria.id}
                                className={({ active, checked }) =>
                                    classNames(
                                        checked
                                            ? 'cursor-not-allowed bg-slate-200 text-gray-900 shadow-lg'
                                            : 'cursor-pointer  bg-white',
                                        'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                    )

                                }
                            >
                                {({ active, checked }) => {
                                    return (
                                        <>
                                            <RadioGroup.Label as="span">{categoria.nome}</RadioGroup.Label>
                                            <span
                                                className={classNames(
                                                    'border-2',
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-md'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )
                                }}
                            </RadioGroup.Option>
                        ))
                    }
                </div>
            </RadioGroup>
        </>

    )
}