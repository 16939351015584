import dayjs from "dayjs";
import { AlunoCurso } from "../models";
import { alunoCursoAmountOfMonths } from "./aluno-curso-amount-of-months";

export function alunoCursoCalcDates(ac: Partial<AlunoCurso>): AlunoCurso['datas_aulas'] {
  let amountOfMonths = alunoCursoAmountOfMonths(ac);
  const target = amountOfMonths * 4;  // Considerando 4 aulas por mês.
  let currentDateIndex = 0;

  if (!ac.data_inicio) return [];
  const dates = [{
    title: '',
    date: ac.data_inicio,
  }];

  while (currentDateIndex !== (target - 1)) {
    const baseDate = dayjs(dates[currentDateIndex].date);
    const newDate = dayjs(dates[currentDateIndex].date).add(1, 'week');
    dates.push({
      title: '',
      date: newDate.format('YYYY-MM-DD'),
    });

    // if (baseDate.month() !== newDate.month()) {
      // amountOfMonths--
    // }
    
    currentDateIndex++;
  }

    // Array.from({ length: alunoCursoAmountOfMonths(ac) - 1 }).forEach((_, i) => {
    //   const baseDate = dayjs(dates[i].date);

    //   dates.push({
    //     title: 'Aula ' + ++currentClassIndex,
    //     date: dayjs(dates[i].date).add(1, 'week').format('YYYY-MM-DD'),
    //   });

    //   const dst = ac.dias_semana_temp ?? [];
    
    //   while (dst.length > 1) {
    //     dates.push({
    //       title: 'Aula ' + ++currentClassIndex,
    //       date: dayjs(dates[0].date).add(dst[1] - dst[0], 'day').format('YYYY-MM-DD'),
    //     });
    
    //     dst.shift();
    //   }
    // });





  return dates;
}
