import { useState } from "react";
import { Button, Checkbox, Input, ListPageLayout, RichText, Select, Txt } from "../../components";
import Form from "../../components/form/Form";
import clausulaApi from "../../services/clausulaApi";
import { useClausulaForm } from "../../hooks/useClausulaForm";

export default function Clausulas() {
  const [id, setId] = useState<number>();

  return (
    <ListPageLayout 
      title="Cláusulas"
      deletion={{ handler: clausulaApi.destroy }}
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (row) => setId(row?.id),
      }}
      table={{
        loader: clausulaApi.all,
        columns: [
            { title: 'Id', field: 'id' },
            { title: 'Ordem', field: 'ordem' },
            { title: 'Nome', field: 'nome' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    register,
    save,
    cursos
  } = useClausulaForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input 
        required
        label="Nome"
        className="col-span-full md:col-span-9"
        {...register('nome')}
      />

      <Input 
        required
        type="number"
        label="Ordem"
        className="col-span-full md:col-span-3"
        {...register('ordem')}
      />

      <Select
          label="Cursos"
          loading={cursos.loading}
          options={cursos.asSelectOptions}
          placeholder={'Cursos'}
          className="col-span-full"
          {...register('cursos_ids')}
          isMulti
      />

      <RichText 
        required
        label="Descrição (Informação que aparecerá no contrato)"
        className="col-span-full" 
        {...register('descricao')}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

