import { PageContainer, useStep, Step,  } from "../../../components";
import { alunoApi } from "../../../services";
import Step2 from "./Step2";
import { Aluno, AlunoCurso } from "../../../models";
import { useParams } from 'react-router';
import Step1 from "./Step1";
import { useForm } from "../../../hooks";

export default function AlunoCursoForm() {
    const { step, prevStep, nextStep } = useStep();
    const { id, matricula } = useParams();
    const canEdit = !matricula;
    const { form, errors, setForm, loading, ...rest } = useForm({
        loader: () => {
            return alunoApi.alunoCursoFind(id, matricula) as any;
        },
        initialData: {
            dobrar_cursos: false,
            aluno_cursos: [
                {
                    curso_id: undefined,
                }  as Partial<AlunoCurso>
            ]
        }  as Partial<Aluno>,
        loaded: (data) => {
            if (! data.id) return data;

            const ac = data.aluno_cursos!.map(alunoCurso => {
                
                setTimeout(() => {
                    (document.querySelector(`[data-inicio="${alunoCurso.id}"]`) as any).value = alunoCurso.data_inicio
                }, 1000)


                const salasTurnosIds = alunoCurso.horarios?.map(horario => horario.salas_turnos_id);
                let dcs: any[] = [];
    
                if (alunoCurso.professor_temp) return alunoCurso;
                if (!alunoCurso.curso) return alunoCurso;
                alunoCurso.curso!.professores?.forEach(professor => {
                    const c = professor.disponibilidade_curso?.filter(dc => {
                        (dc as any).professor = professor;
                        return salasTurnosIds?.includes(dc.id);
                    }) as any;
                    if (c.length) dcs = [...dcs, ...c];
                });
    
                alunoCurso.dias_semana_temp = dcs.map(dc => dc.sala_disponibilidade_turno.salas_disponibilidade?.dia);
                alunoCurso.professor_temp = dcs[0]?.professor;
    
                alunoCurso.repasse = alunoCurso.repasses?.length ? 'professor' : 'espaco';

                return {
                    ...alunoCurso,
                    ...(alunoCurso.data ? JSON.parse(alunoCurso.data) : {})
                };
            });

            return { ...data, aluno_cursos: ac };
        }
    });

    return (
        <PageContainer>
            <Step step={step} steps={[
                <Step1 
                    canEdit={canEdit}
                    nextStep={nextStep}
                    {...{ form, errors, setForm, loading, ...rest }}
                />,

                <Step2 
                    canEdit={canEdit}
                    prevStep={prevStep}
                    id={id}
                    matricula={matricula}
                    {...{ form, errors, setForm, loading, ...rest }}
                />
            ]} />
        </PageContainer>
    );
}