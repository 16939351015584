import { useEffect, useState } from "react";
import { Button, Checkbox, Input, ListPageLayout } from "../../components";
import { repasseApi } from "../../services";
import InputCurrency from "../../components/inputCurrency";
import Form from "../../components/form/Form";
import { useRepasseForm } from "../../hooks";

export default function Repasses() {
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [info, setInfo] = useState({ quantidade: 0, total: 0 });
  const [id, setId] = useState<number>();

  async function loadInfo() {
    const { data, isError } = await repasseApi.listAll({ 'where': `data,${date},${date}` });
    if (isError ) return;
    let v = 0;
    let t = 0;
    data.forEach((item: any) => {
      v += Number(item.valor);
      t++;
    });
    setInfo({ quantidade: t, total: v});
  }

  useEffect(() => {
    loadInfo();
  }, [date])

  return (
    <ListPageLayout 
      title="Repasses"
      deletion={{ handler: repasseApi.destroy }}
      queries={{
        'where': `data,${date},${date}`
      }}
      header={
        <div className="grid grid-cols-12 gap-4">
          <Input 
            type="date"
            className="col-span-full lg:col-span-2"
            value={date}
            setValue={(e) => setDate(e)}
          />

          <Input
            disabled
            value={`${info.quantidade} ${info.quantidade === 1 ? 'repasse' : 'repasses'}`}
            className="col-span-full lg:col-span-3"
          />

          <InputCurrency
            value={info.total}
            className="col-span-full lg:col-span-3"
            onChange={(value: any) => {}}
          />
        </div>
      }
      registerModal={{
        hideCreationButton: true,
        form: <Formulario id={id} />,
        onOpen: (item) => setId(item?.id),
      }}
      table={{
        loader: repasseApi.all,
        columns: [
            { title: 'Professor', field: 'professor.nome' as any },
            { title: 'Pix', field: 'professor.pix' as any },
            { title: 'Repasse', field: 'valor', columnType: 'money' },
            { title: 'Pago', field: 'pago', columnType: 'YesOrNo' },
        ],
      }}
    />
  );
}


function Formulario({ id }: { id?: number }) {
  const { 
    form,
    setForm,
    errors,
    register,
    save,
  } = useRepasseForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input 
        required
        disabled
        label="Nome"
        className="col-span-full"
        value={form.professor?.nome}
      />

      <InputCurrency
          label="Valor"
          value={form.valor}
          className="col-span-full lg:col-span-6"
          onChange={(value: any) => setForm({ ...form, valor: value })}
          error={errors?.valor}
      />

      <Input 
        required
        label="Data"
        type="date"
        className="col-span-full lg:col-span-6"
        {...register('data')}
      />

      <Checkbox 
        label="Está pago?"
        className="col-span-full"
        {...register('pago')}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

