/* eslint-disable import/no-anonymous-default-export */
import { Curso } from '../models';
import { QueryParams, ISalas } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'cursos';

export type Params = {
    query?: {
        relations?: ('categoria' | 'valores' | 'salas' | 'professores' | 'professores.disponibilidadeCurso' | 'professores.disponibilidade')[]
    }
}

export default {
    all: async (params?: QueryParams) => getAll<ISalas>({ endpoint, params }),
    listAll: async (params?: Params) => listAll<Curso>({ endpoint, params }),
    one: async (id: string) => getOne<ISalas>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<ISalas>) => create<ISalas>({ endpoint, formData }),
    update: async (id: string, formData: Partial<ISalas>) => update<ISalas>({ endpoint, formData, id }),
    valoresCursos: async () => {
        try {
            const { data } = await api.get('/cursos/valor');
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    getCursosCadastroProfessor: async (id: any) => {
        try {

            const { data } = await api.get(`${endpoint}/dados-cadastro-aluno?ids=${id}`);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
