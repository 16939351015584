import { tw } from "@matheuscaetano/helprs";

export type TxtProps = {
  variant?: 'page-title' | 'title';
  className?: string;
  children?: React.ReactNode; 
}

export function Txt({
  variant,
  className,
  children
}: TxtProps) {
  if (variant === 'page-title') {
    return (
      <h1 className={tw(className, 'text-3xl')}>
        {children}
      </h1>
    )
  }

  if (variant === 'title') {
    return (
      <h2 className={tw(className, 'text-2xl')}>
        {children}
      </h2>
    )
  }

  return (
    <span className={className}>{children}</span>
  );
}
