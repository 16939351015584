import SelectDias from "../selectDias";
import React, {useState} from "react";
import {IDiasTurnosComponent} from "../../types";
import diasApi from "../../services/diasApi";
import TurnosComponent from "../turnosComponent";
import Obj from "../../helpers/Obj";

export default function DiasTurnosComponent({
                                                selectedDias,
                                                onSelectDias,
                                                optionsTurnos,
                                                arraySelectedDias,
                                                setArraySelectedDias,
                                                mult,
}: IDiasTurnosComponent) {
    const [dias, setDias] = useState(Obj.copy(diasApi));
    const selectDias = (data: number[]) => {
        setArrayDiasSelected(data);
        onSelectDias(data)
    }

    const setArrayDiasSelected = (diasIds: any) => {
        let dataDiasSelect: any = [];
        if (Number.isInteger(diasIds[0])) {
            const arraySelected = [...arraySelectedDias];
            let newArray: any[] = [];
            if (arraySelected.length){
                diasIds.map((slD: any) => {
                    const d = dias.filter((d: any) => d.id === slD)[0];
                    const findArr = arraySelected.find((d) => d.id === slD);

                    if (findArr === undefined) {
                        if (!d?.turnos.length){
                            // @ts-ignore
                            d.turnos.push(optionsTurnos[0]);
                        }
                        arraySelected.push(d);
                        newArray = arraySelected;
                    }else{
                        newArray.push(findArr);
                    }
                });

                dataDiasSelect = newArray;
            }else{
                diasIds.filter((slD: any) => {
                    let diasSl = dias.filter((d: any) => d.id === slD)[0];
                    if (diasSl.turnos.length){
                        diasSl.turnos = [];
                    }
                    // @ts-ignore
                    diasSl.turnos.push(optionsTurnos[0]);
                    dataDiasSelect.push(diasSl);
                })
            }
        } else {

            diasIds.map((ds:any) => {
                let diasSl = dias.filter((d: any) => d.id === ds.dia)[0];

                if (diasSl.turnos.length){
                    diasSl.turnos = [];
                }
                ds.disponibilidade_turnos.map((tur: any) => {
                    const turno = optionsTurnos.filter((optT: any) => optT.value === tur.id)[0]

                    // @ts-ignore
                    diasSl.turnos.push(turno);
                });
                dataDiasSelect.push(diasSl);
            });
        }
        setArraySelectedDias(dataDiasSelect);
    }


    return(
        <>
            <SelectDias selectedDias={selectedDias} setSelectedDias={selectDias} />
            <TurnosComponent
                mult={mult}
                arraySelectedDias={arraySelectedDias}
                setArraySelectedDias={setArraySelectedDias}
                optionsTurnos={optionsTurnos}
            />
        </>
    )
}
