/* eslint-disable @typescript-eslint/no-explicit-any */
import {ForgotPassData, LoginAdminData, LoginData, Usuario} from '../types';
import api, { RequestError, RequestResponse } from './api';

export default  {
  async login(loginData: LoginAdminData): Promise<RequestError | RequestResponse<{ status: true; message: string; token: string; usuario: Usuario; menu: any }>> {
    try {
      const { data } = await api.post('auth/login', loginData);
      return { data, isError: false };
    } catch (err: any) {
      return { 
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },

  async forgotPass(forgotPassData: ForgotPassData): Promise<RequestError | RequestResponse<{
    status: true;
    message: string;
  }>> {
    try {
      const { data } = await api.post('usuarios/resetar-senha', forgotPassData);
      return { data, isError: false };
    } catch (err: any) {
      console.log(err);
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  }
};
