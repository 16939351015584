import {QueryParams, ITurno} from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'turnos';

export default {
    all: async (params?: QueryParams) => getAll<ITurno>({ endpoint, params }),
    listAll: async () => listAll<ITurno>({ endpoint }),
    one: async (id: string) => getOne<ITurno>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<ITurno>) => create<ITurno>({ endpoint, formData }),
    update: async (id: string, formData: Partial<ITurno>) => update<ITurno>({ endpoint, formData, id }),
};
