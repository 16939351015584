import React from "react";
import {ICategorias, ITabs} from "../../types";

export default function TabsCategoria({categorias, setCategoriaId, openId}: ICategorias) {
    const [openTab, setOpenTab] = React.useState(categorias[0] ? categorias[0].id : 1);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                        role="tablist"
                    >
                        {
                            categorias.map((categoria: any) => {
                                return (
                                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                                        <a
                                            className={
                                                "text-lg font-bold uppercase px-5 py-3 shadow-lg  block leading-normal " +
                                                (openTab === categoria.id
                                                    ? `bg-${categoria.cor} text-white border-[3px] border-slate-900`
                                                    : `bg-white border-[3px] border-slate-200 text-slate-400`)
                                            }
                                            onClick={e => {
                                                e.preventDefault();
                                                setOpenTab(categoria.id);
                                                setCategoriaId(categoria.id);
                                            }}
                                            data-toggle="tab"
                                            href="#"
                                            role="tablist"
                                        >
                                            {categoria.nome}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}
