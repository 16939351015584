import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Button from "../../components/button/Button";
import useTable from "../../hooks/useTable";
import cursosApi from "../../services/cursosApi";
import Table from "../../components/table/Table";
import useModal from "../../hooks/useModal";
import Modal from "../../components/modal/Modal";
import Form from "../../components/form/Form";
import CheckCategorias from "../../components/checkCategorias";
import InputSelect from "../../components/inputSelect";
import Input from "../../components/input/Input";
import salasApi from "../../services/salasApi";
import Alert from "../../helpers/Alert";
import categoriasApi from "../../services/categoriaApi";
import {useNavigate} from "react-router";



export default function Cursos() {
    const [loading, setLoading] = useState(true);
    const [taleView, setTaleView] = useState('tabela_padrao');
    const navigate = useNavigate();
    const { modalProps, open, close } = useModal();
    const [state, setState]: any[] = useState({
        id: null,
        categoria_id: 1,
        salas: [],
        descricao: '',
        idade: '',
        quantidade_alunos: '',
    });
    const [selectedCategoria, setSelectedCategoria] = useState(1)
    const [salasOptions, setSalasOptions]: any[] = useState();
    const [salasSelected, setSalasSelected]: any[] = useState([]);
    const [salas, setSalas]: any[] = useState([]);
    const [categorias, setCategorias]: any[] = useState([]);

    function handleChange(value: string | number | boolean, name: any) {
        setState({ ...state, [name]: value });
    }

    const { tableProps, onSearch } = useTable({
        dataLoader: async (params) => {
            if (params) {
                params['relations'] = `salas`;
            }
            const { data, isError } = await cursosApi.all(params);
            setLoading(false);
            return isError ? false : data;
        }
    });

    const columns = [
        { title: 'Id', field: 'id' },
        { title: 'Descrição', field: 'descricao' },
        { title: 'Categoria', field: 'categoria.nome' },
    ];

    const onEdit = (cursosData: any) =>{
        setSelectedCategoria(cursosData.categoria_id);
        const salasSelec = cursosData.salas.map((sl: any) => {
            return {
                value: sl.id,
                label: sl.nome
            }
        });
        setSalasSelected(salasSelec);
        setSalasOptions(filterSalasCategorias(cursosData.categoria_id));
        setState({
            id: cursosData.id,
            categoria_id: cursosData.categoria_id,
            salas: cursosData.salas,
            descricao: cursosData.descricao,
            idade: cursosData.idade,
            quantidade_alunos: cursosData.quantidade_alunos,
        });
        open();
    }

    const onDelete = async (cursosData: any) =>{
        const confirm = await Alert.confirm('Tem certeza que deseja excluir?', 'Excluir sala?');
        if (! confirm) return;
        Alert.await('Excluindo sala...');
        const { data, isError } = await cursosApi.destroy(cursosData.id);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        Alert.close();
        await onSearch('');
    }

    const getDados = async () => {
        const [salasData, categoriasData] = await Promise.all([
            salasApi.listAll(),
            categoriasApi.listAll()
        ]);
        if (!salasData.isError){
            setSalas(salasData.data);

        }
        if (!categoriasData.isError){
            setCategorias(categoriasData.data);
        }
    }

    useEffect(() => {
        getDados();
    }, [])

    const onSelect = (e: any, input: string) => {
        if (input === 'salas'){
            const arrayIds = e.map((d:any) => d.value);
            setState({ ...state, [input]: arrayIds })
            setSalasSelected(e);
        }
    }

    const limpaDados = () => {
        setState({
            id: null,
            categoria_id: 1,
            salas: [],
            descricao: '',
            idade: '',
            quantidade_alunos: '',
        });
        setSelectedCategoria(1);
        setSalasSelected([])
    }

    const onSubmit = async () =>{
        const {data, isError} = state.id ?
            await cursosApi.update(state.id, state)
            :
            await cursosApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        const mensage = state.id ? "Editado com sucesso!" : "Cadastrado com sucesso!"
        Alert.success(mensage);
        close();
        limpaDados();
        //atualizar tabela aqui
        await onSearch('');
    }

    const filterSalasCategorias = (e: any) => {
        const salasData = [...salas];
        let newArraySalas : any[] = [];
        salasData.map((sd: any) => {
            if (sd.categoria_id === e) {
                newArraySalas.push(sd);
            }
        });
        return newArraySalas.map((opt: any) => {
            return {
                value: opt.id,
                label: opt.nome,
            }
        });
    }

    const selectCategorias = (e: number) => {
        setLoading(true);
        setSalasSelected([]);
        const optSalas = filterSalasCategorias(e);
        setState({ ...state, 'categoria_id': e })
        setSelectedCategoria(e);
        setSalasOptions(optSalas)
        setLoading(false);
    }

    const openModalForm = () => {
        limpaDados();
        const optSalas = salas.filter((sl:any) => sl.categoria_id === selectedCategoria).map((opt: any) => {
           return{
               value: opt.id,
               label: opt.nome,
           }
        });
        setSalasOptions(optSalas)
        open()
    }

    return(
        <>
            <PageContainer>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-row justify-between p-2 w-full'}>
                        <h1 className={'text-3xl flex items-center'}>Cursos</h1>
                        <Button className={'bg-constancia hover:bg-constancia-100 text-white'} onClick={() => openModalForm()}>Novo</Button>
                    </div>
                    {/* <div className={'flex flex-row  p-2 w-full'}>
                        <Button className={'bg-conhecimento hover:bg-conhecimento-100 text-white'} onClick={() => onSubmit()}>Buscar</Button>
                    </div> */}
                </div>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-row  p-2 w-full'}>
                        <Button className={'bg-conhecimento hover:bg-conhecimento-100 text-white'} onClick={() => navigate('/cursos/valores')}>
                            Valores curso
                        </Button>
                    </div>
                </div>
                <div className={'mt-5 '}>
                    <Table
                        loading={loading}
                        columns={columns}
                        data={tableProps.data}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onPageChange={tableProps.onPageChange}
                    />
                </div>
            </PageContainer>
            <Modal size="lg" title="CADASTRAR NOVO CURSO" {...modalProps}>
                {
                    loading ?
                        <span className="flex items-center justify-center w-full">
                          <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                        :
                        <fieldset className="mt-1">
                            <Form onSubmit={() => onSubmit()}>
                                <CheckCategorias
                                    value={selectedCategoria}
                                    onChange={selectCategorias}
                                    categorias={categorias}
                                />

                                <div className={'grid grid-cols-1'}>
                                    <InputSelect
                                        selected={salasSelected}
                                        loading={loading}
                                        options={salasOptions}
                                        onChangeSelect={(e: any) => onSelect(e, 'salas')}
                                        placeholder={'Sala(s) apropriadas'}
                                        isMulti={true}
                                    />
                                </div>
                                <div className="grid grid-cols-1 mt-4">
                                    <Input
                                        label="DESCRIÇÃO / CURSO"
                                        className="col-span-1"
                                        value={state?.descricao}
                                        setValue={v => handleChange(v, 'descricao')}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4 mt-4">
                                    <Input
                                        label="Idade"
                                        type={'number'}
                                        className="col-span-1"
                                        value={state?.idade}
                                        setValue={v => handleChange(v, 'idade')}
                                    />
                                    <Input
                                        label="Quantidade"
                                        type={'number'}
                                        className="col-span-1"
                                        value={state?.quantidade_alunos}
                                        setValue={v => handleChange(v, 'quantidade_alunos')}
                                    />
                                </div>

                                <div className="flex justify-end mt-4">
                                    <Button type={'submit'} className={'bg-constancia hover:bg-constancia-100 text-white '} >Concluir</Button>
                                </div>
                            </Form>
                        </fieldset>
                }
            </Modal>
        </>
    )
}