import { AlunoCurso } from "../models";

export function alunoCursoAmountOfMonths(alunoCurso: Partial<AlunoCurso>) {
  if (!alunoCurso.planos?.length) return 0;

  let months = 0;

  const planosToAmountOfMonths = {
      'Outro': 1, 
      'Mensal': 1, 
      'Bimestral': 2, 
      'Trimestral': 3, 
      'Semestral': 6,
  };

  alunoCurso.planos.forEach((plano) => {
      months = months + planosToAmountOfMonths[plano]
  });

  return months;
}