import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Str from '../../../helpers/Str';

export type RichTextProps = {
  value?: string;
  setValue?: (value: string) => void;
  className?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  name?: string;
  required?: boolean;
}

export function RichText({ 
  value, 
  setValue, 
  className, 
  hideLabel,
  label,
  required,
  error,
  ...rest 
}: RichTextProps) {
  return (
    <label className={Str.tw('w-full', className)}>
      {!hideLabel && <span>{label} {required ? <span className='text-red-600'>*</span> : ''}</span>}
      <ReactQuill 
        {...rest}
        theme="snow" 
        value={value} 
        onChange={setValue}
        modules={{
          toolbar: ['bold', 'italic', 'underline']
        }}
      />
      {!!error?.length && <span className="text-red-600 ">{error}</span>}
    </label>
  )
}