import { useEffect, useState } from 'react';

import { PaginatedData, QueryParams } from '../../types';

export interface UseTableProps<T> {
  dataLoader: (params?: QueryParams) => Promise<PaginatedData | false>;
  onItemClick?: (item: T) => void;
  denyInitialLoad?: boolean;
}

export default function useTable<T>({
  dataLoader,
  onItemClick,
  denyInitialLoad
}: UseTableProps<T>) {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const [data, setData] = useState<PaginatedData>();

  async function loadData(params?: any) {
    setLoading(true);
    let p: any = { page, perPage };

    if (params?.q?.trim().length) {
      p = { ...p, search: params.q.trim() };
    }

    const response = await dataLoader(p);
    if (! response) return;
    setData(response);
    setLoading(false);
  }

  useEffect(() => {
    if (denyInitialLoad && !initialized) {
      setInitialized(true);
      setLoading(false);
      return;
    }
    setInitialized(true);
    loadData();
  }, [page, perPage]);

  return {
    data,
    onSearch: (q: string) => loadData({ q }),
    loadData,
    tableProps: {
      data,
      loading,
      onItemClick,
      onPageChange: setPage,
      onPerPageChange: (perP: any) => {
        setPage(1);
        setPerPage(perP);
      },
    }
  };
}
