import { QueryParams, ICategoria } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'categorias';

export default {
    all: async (params?: QueryParams) => getAll<ICategoria>({ endpoint, params }),
    listAll: async () => listAll<ICategoria>({ endpoint }),
    one: async (id: string) => getOne<ICategoria>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<ICategoria>) => create<ICategoria>({ endpoint, formData }),
    update: async (id: string, formData: Partial<ICategoria>) => update<ICategoria>({ endpoint, formData, id }),
};
