import { Professor } from "../models";
import dias from "../services/diasApi";

export function turnosHorarios(professor?: Professor, diasSemana = [1,2,3,4,5,6]) {
  const options: { label: string, value: number }[] = [];

  const finals = (professor?.disponibilidade_curso?.filter(dc => {
    return dc.horario_sala_turno_inicial_id && dc.ultima_hora;
  }) ?? []);

  const dispTurnos = (professor?.disponibilidade_curso?.filter(dc => {
    return  !dc.desabilitado && !dc.horario_sala_turno_inicial_id && !dc.ultima_hora;
  }) ?? []).map(dc => {
    return {
      ...dc,
      final_aula: finals.find(f => f.horario_sala_turno_inicial_id === dc.id)?.final_aula ?? dc.final_aula,
    }
  });

  dispTurnos?.filter(dc => !dc.desabilitado).forEach(disponibilidadeCurso => {
      if (
          ! disponibilidadeCurso.sala_disponibilidade_turno?.salas_disponibilidade || 
          disponibilidadeCurso.professor_id !== professor?.id ||
          ! diasSemana?.includes(disponibilidadeCurso.sala_disponibilidade_turno?.salas_disponibilidade.dia)
      ) return;

      const value = disponibilidadeCurso.id;

      const inicio = disponibilidadeCurso.inicio_aula.slice(0, 5);
      const fim    = disponibilidadeCurso.final_aula.slice(0, 5);
      const dia    = dias.find(d => d.id === disponibilidadeCurso.sala_disponibilidade_turno?.salas_disponibilidade!.dia)!.name;

      const label = `${dia} - ${inicio} até ${fim}`;

      options.push({ label, value });
  });
  return options;
}
