import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import locale from '@fullcalendar/core/locales/pt-br';
import { DateSelectArg, EventClickArg, EventSourceInput } from '@fullcalendar/core';
import { Dt } from '../../../helpers';

export type CalendarProps = {
  onSelect?: (info: DateSelectArg) => void;
  onEventClick?: ((arg: EventClickArg) => void);
  events?: EventSourceInput;
}

export function Calendar({
  onSelect,
  onEventClick,
  events
}: CalendarProps) {
  return (
    <FullCalendar
      selectable
      selectAllow={(selectionInfo) => { // Permite que seja selecionado apenas um dia por vez
        let startDate = selectionInfo.start;
        let endDate = selectionInfo.end;
        endDate.setSeconds(endDate.getSeconds() - 1);  // allow full day selection
        if (startDate.getDate() === endDate.getDate()) {
            return true;
        } else {
            return false;
        }
      }}
      defaultAllDay
      select={onSelect}
      eventClick={onEventClick}
      plugins={[ interactionPlugin, multiMonthPlugin ]}
      locale={locale}
      initialView="multiMonthYear"
      height="86vh"
      events={events}
      initialDate={new Date(`${Dt.currentYear()}-02-01`)}
      />
  )
}