import { z } from 'zod';
import { Routes } from './router';

z.setErrorMap((issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.minimum === 1)
    return { message: `Campo é obrigatório` };
  }

  if (issue.code === z.ZodIssueCode.invalid_type) {
    return { message: `Campo é obrigatório` };
  }

  return { message: ctx.defaultError };
});

export default function App() {
  return <Routes />;
}