import { useEffect, useState } from "react";
import { Button, Checkbox, DashCard, Input, ListPageLayout } from "../../components";
import Form from "../../components/form/Form";
import { despesaExtraApi } from "../../services";
import { useDespesaExtraForm } from "../../hooks";
import InputCurrency from "../../components/inputCurrency";

export default function DespesaExtras() {
  const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 10));
  const [info, setInfo] = useState({ quantidade: 0, total: 0 });
  const [id, setId] = useState<number>();

  async function loadInfo() {
    const { data, isError } = await despesaExtraApi.listAll({ 'where': `data,${date},${date}` });
    if (isError) return;
    let v = 0;
    let t = 0;
    data.forEach((item: any) => {
      v += Number(item.valor);
      t++;
    });
    setInfo({ quantidade: t, total: v});
  }

  useEffect(() => {
    loadInfo();
  }, [date])

  return (
    <ListPageLayout 
      title="Despesas Extras"
      deletion={{ handler: despesaExtraApi.destroy }}
      queries={{
        'where': `data,${date},${date}`
      }}
      header={
        <div className="grid grid-cols-12 gap-4 items-end">
          <Input
              type="date"
              className="col-span-full lg:col-span-2"
              value={date}
              setValue={(e) => setDate(e)}
          />

          <div className="col-span-full lg:col-span-1"></div>

          <div className="grid grid-cols-3 gap-4 col-span-full lg:col-span-9 align-bottom items-center">
            <div></div>
            <DashCard label="Quantidade" value={info.quantidade} />
            <DashCard label="Total" value={info.total} type="money" />
          </div>
        </div>
      }
      registerModal={{
        form: <Formulario id={id} />,
        onOpen: (item) => setId(item?.id),
      }}
      table={{
        loader: despesaExtraApi.all,
        columns: [
            { title: 'Nome', field: 'nome' },
            { title: 'Valor', field: 'valor', columnType: 'money' },
            { title: 'Data', field: 'data', columnType: 'date' },
            { title: 'Pago', field: 'pago', columnType: 'YesOrNo' },
        ],
      }}
    />
  );
}

function Formulario({ id }: { id?: number }) {
  const { 
    form,
    setForm,
    errors,
    register,
    save,
  } = useDespesaExtraForm({ id });

  return (
    <Form className="grid grid-cols-12 gap-4" onSubmit={save}>
      <Input 
        required
        label="Nome"
        className="col-span-full"
        {...register('nome')}
      />

      <InputCurrency
          label="Valor"
          value={form.valor}
          className="col-span-full lg:col-span-6"
          onChange={(value: any) => setForm({ ...form, valor: value })}
          error={errors?.valor}
      />

      <Input 
        required
        label="Data"
        type="date"
        className="col-span-full lg:col-span-6"
        {...register('data')}
      />

      <Checkbox 
        label="Está pago?"
        className="col-span-full"
        {...register('pago')}
      />

      <footer className="flex items-center justify-end col-span-full">
        <Button variant="constancia" type="submit">Cadastrar</Button>
      </footer>
    </Form>
  );
}

