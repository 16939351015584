/* eslint-disable import/no-anonymous-default-export */
import { Professor } from '../models';
import {QueryParams, IProfessor, SimpleQueryParams} from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll, RequestError, RequestResponse, makeCrudObject} from './api';

const endpoint = 'professores';

export default {
    ...makeCrudObject<Professor>(endpoint),
    all: async (params?: QueryParams) => getAll<IProfessor>({ endpoint, params }),
    listAll: async () => listAll<IProfessor>({ endpoint }),
    one: async (id: string, params?:SimpleQueryParams ) => getOne<IProfessor>({ endpoint, id, params }),
    create: async (formData: Partial<IProfessor>) => create<IProfessor>({ endpoint, formData }),
    update: async (id: string, formData: Partial<IProfessor>) => update<IProfessor>({ endpoint, formData, id }),
    async getProfessorHorarios(dataConsulta?: any): Promise<RequestError | RequestResponse<any[]>> {
        try {
            const { data } = await api.get(`${endpoint}/horarios${dataConsulta}`);
            return { data, isError: false };
        } catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
