import { Professor } from "../models"
import { ISelect } from "../types";

export function mapProfessoresToSelectOptions(professores?: Professor[]): ISelect[] {
  return professores?.map(professor => {
    return {
      value: professor.id,
      label: professor.nome
    }
  }) ?? [];
}